import client from '../../../config/api';

import {
  LOCAL_ADD_DOCUMENT,
  LOCAL_RENAME_DOCUMENT,
  LOCAL_DELETE_DOCUMENT,
  LOCAL_MOVE_DOCUMENTS,
} from '../mutations/mutationTypes';

export default {
  async ADD_DOCUMENT({ commit }, payload) {
    const { jobId, data } = payload;
    commit(LOCAL_ADD_DOCUMENT, data);
    return client.post(`/documents/job/${jobId}`, data);
  },

  async RENAME_DOCUMENT({ commit }, payload) {
    const { id, newName } = payload;
    commit(LOCAL_RENAME_DOCUMENT, { id, newName });
    return client.patch(`/documents/rename/${id}`, { newName });
  },

  async DELETE_DOCUMENT({ commit }, ids) {
    commit(LOCAL_DELETE_DOCUMENT, ids);
    const result = await client.post('/documents/delete', { ids });
    return result.data;
  },

  async MOVE_DOCUMENTS({ commit }, payload) {
    commit(LOCAL_MOVE_DOCUMENTS, payload);
    const result = await client.post('/documents/move', payload);
    return result.data;
  },

  async GET_JOB_DOCUMENTS(_, payload) {
    const { jobId, pathIDs } = payload;
    const result = await client.post(`/documents/folder/job/${jobId}`, {
      pathIDs,
    });
    return result.data;
  },

  async GET_RECYCLE_BIN(_, payload) {
    const { jobId } = payload;
    return client.get(`/documents/job/${jobId}/deleted/${true}`);
  },

  RESTORE_DOCUMENTS(_, payload) {
    return client.post('/documents/restore', { ids: payload });
  },

  async EDIT_DOCUMENT_NAME_DESCRIPTION(_, payload) {
    const { id } = payload;
    return client.patch(`/documents/changeDetails/${id}`, payload);
  },
};
