<template>
  <div class="">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="closeButton">
      <b-button
        size="is-small"
        icon-right="close"
        type="is-text"
        v-on:click="cancelForm"
      />
    </div>
    <div class="container">
      <info-page
        @goToNextStep="handleInfoPageForm($event)"
        :hidden="activeStep !== 0"
      ></info-page>
      <select-products
        @selectedProducts="handleSelectedProducts($event)"
        @allJobsChanged="allJobsChanged($event)"
        :hidden="activeStep !== 1"
        @tableHasBeenSelected="handleTableSelected"
      ></select-products>
      <table-configuration
        :selectedJobs="selectedJobsWithTable"
        :activeStep="this.activeStep"
        @selectedConfiguration="handleSelectedConfiguration($event)"
        :hidden="!tableSelected || activeStep !== 2"
      >
      </table-configuration>
      <select-tasks
        :ref="'selectTasks'"
        :hidden="!showSelectTasks"
        :selectedJobs="selectedJobs"
        :formattedJobs="formattedJobs"
        @selectedTasks="handleSelectedTasks($event)"
        @taskToRemove="handleTaskToRemove($event)"
      ></select-tasks>
      <h1 class="label" :hidden="!showSelectSites">Select Sites</h1>
      <select-sites
        :initialSiteId="user.location"
        @selectedSites="handleSelectedSites($event)"
        :hidden="!showSelectSites"
      ></select-sites>
      <review-workorder
        v-if="showReviewOrder"
        :orderCreationFields="orderCreationFields"
        :selectedTasks="arrayOfObjectsSelected"
        :siteNames="siteNames"
      ></review-workorder>
      <section>
        <b-notification
          auto-close
          type="is-success"
          :active.sync="showSuccess"
          :duration="3000"
          aria-close-label="Close notification"
        >
          New Order Created Successfully!
        </b-notification>
        <b-notification
          auto-close
          type="is-danger"
          :active.sync="showFail"
          :duration="5000"
          aria-close-label="Close notification"
        >
          Error while creating new order! Please try again.
        </b-notification>
        <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation && activeStep !== 0"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
        >
          <b-step-item step="1" label="1" :clickable="stepsAllowed - 1 >= 0">
          </b-step-item>

          <b-step-item
            step="2"
            label="2"
            :clickable="stepsAllowed - 2 >= 0"
            :type="{ 'is-success': isProfileSuccess }"
          >
          </b-step-item>

          <b-step-item
            step="3"
            :visible="allowNextSelectTasks"
            label="3"
            :clickable="stepsAllowed - 3 >= 0"
          >
          </b-step-item>

          <b-step-item
            :step="allowNextSelectTasks ? 4 : 3"
            :label="allowNextSelectTasks ? '4' : '3'"
            :clickable="stepsAllowed - 4 >= 0"
            disabled
          >
          </b-step-item>

          <b-step-item
            :step="allowNextSelectTasks ? 5 : 4"
            :label="allowNextSelectTasks ? '5' : '4'"
            :clickable="stepsAllowed - 5 >= 0"
            disabled
          >
          </b-step-item>

          <b-step-item
            :visible="tableSelected && !jobHasJustTables"
            :step="6"
            label="6"
            :clickable="stepsAllowed - 6 >= 0"
            disabled
          >
          </b-step-item>

          <template slot="navigation" slot-scope="{ previous, next }">
            <b-button
              style="margin-left: 10px"
              :hidden="activeStep === 0"
              outlined
              icon-pack="mdi"
              icon="chevron-left"
              icon-left="chevron-left"
              size="is-medium"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
            >
            </b-button>
            <b-button
              style="margin-left: 5px"
              :hidden="activeStep === 0"
              outlined
              type="next"
              :icon="nextIcon"
              icon-pack="mdi"
              :icon-right="nextIcon"
              size="is-medium"
              :disabled="!allowNextStep && activeStep !== 4"
              @click.prevent="nextClick(next)"
              :class="stepClass"
            >
            </b-button>
          </template>
        </b-steps>
      </section>
    </div>
  </div>
</template>

<script>
import InfoPage from './AddOrderInfoPage';
import SelectProducts from './SelectProducts';
import SelectTasks from './SelectTasks';
import SelectSites from './SelectSites';
import ReviewWorkOrder from './ReviewWorkOrder';
import TableConfiguration from './TableConfiguration';

export default {
  name: 'publisher-add-order',
  components: {
    'info-page': InfoPage,
    'select-products': SelectProducts,
    'select-tasks': SelectTasks,
    'select-sites': SelectSites,
    'review-workorder': ReviewWorkOrder,
    'table-configuration': TableConfiguration,
  },
  data() {
    return {
      activeStep: 0,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: 'chevron-left',
      // nextIcon: 'chevron-right',
      labelPosition: 'bottom',

      stepsAllowed: 0,
      selectedTableTasks: [],
      selectedJobs: [],
      orderCreationFields: {},
      selectedTasksToReview: '',
      arrayOfObjectsSelected: [],
      insertedTasksInString: [],
      formattedJobs: [],
      siteNames: [],
      allowNextSelectTasks: false,
      allowNextSelectSites: false,
      allowNextReview: false,
      selectedSitesIds: [],
      showSuccess: false,
      showFail: false,
      tableSelected: false,
      jobsWithTables: [],
      storageCounter: 0,
      selectedJobsWithTable: [],
      TableConfiguration: null,

      isLoading: false,
      jobHasJustTables: false,
      allJobs: [],
    };
  },
  async mounted() {
    await this.$store.dispatch('getSites');
  },

  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    jobs() {
      return this.$store.state.jobs;
    },
    sites() {
      return this.$store.state.sites;
    },
    nextIcon() {
      if (
        (this.selectedJobsWithTable.length > 0 && this.activeStep === 5) ||
        (this.selectedJobsWithTable.length < 1 && this.activeStep === 4) ||
        (this.selectedJobsWithTable.length > 0 &&
          this.jobHasJustTables &&
          this.activeStep === 4)
      ) {
        return 'check';
      }
      return 'chevron-right';
    },
    stepClass() {
      if (
        (this.selectedJobsWithTable.length > 0 && this.activeStep === 5) ||
        (this.selectedJobsWithTable.length < 1 && this.activeStep === 4) ||
        (this.selectedJobsWithTable.length > 0 &&
          this.jobHasJustTables &&
          this.activeStep === 4)
      ) {
        return 'review-btn';
      }
      return '';
    },
    allowNextStep() {
      if (this.activeStep === 1) {
        return true;
      }
      if (
        (this.activeStep === 2 && this.allowNextSelectSites) ||
        (this.selectedJobsWithTable.length > 0 &&
          this.activeStep === 3 &&
          this.allowNextSelectSites)
      ) {
        return true;
      }
      if (
        (this.activeStep === 3 && this.allowNextReview) ||
        (this.selectedJobsWithTable.length > 0 &&
          this.activeStep === 4 &&
          this.allowNextSelectSites) ||
        (this.selectedJobsWithTable.length > 0 && this.activeStep === 5)
      ) {
        return true;
      }
      return false;
    },
    newJobError() {
      return this.$store.state.jobErrors;
    },
    showSelectTasks() {
      // It has table but also tasks
      if (
        this.selectedJobsWithTable.length > 0 &&
        !this.jobHasJustTables &&
        this.activeStep === 3
      ) {
        return true;
      }
      if (this.jobHasJustTables) {
        return false;
      }
      if (this.selectedJobsWithTable.length < 1 && this.activeStep === 2) {
        return true;
      }
      return false;
    },
    showSelectSites() {
      // It has table but also tasks
      if (
        this.selectedJobsWithTable.length > 0 &&
        !this.jobHasJustTables &&
        this.activeStep === 4
      ) {
        return true;
      }
      if (this.jobHasJustTables && this.activeStep === 3) {
        return true;
      }
      if (this.selectedJobsWithTable.length < 1 && this.activeStep === 3) {
        return true;
      }
      return false;
    },
    showReviewOrder() {
      // It has table but also tasks
      if (
        this.selectedJobsWithTable.length > 0 &&
        !this.jobHasJustTables &&
        this.activeStep === 5
      ) {
        return true;
      }
      if (this.jobHasJustTables && this.activeStep === 4) {
        return true;
      }
      if (this.selectedJobsWithTable.length < 1 && this.activeStep === 4) {
        return true;
      }
      return false;
    },
  },
  watch: {
    activeStep: {
      handler(newValue) {
        if (
          (this.jobHasJustTables && newValue === 4) ||
          (this.selectedJobsWithTable.length > 0 && newValue === 5)
        ) {
          this.handleSelectedTasks([]);
        }
      },
    },

    arrayOfObjectsSelected(newValue) {
      if (!newValue || (Array.isArray(newValue) && newValue.length < 1)) {
        this.allowNextSelectSites = false;
      }
    },
  },

  methods: {
    cancelForm() {
      this.$router.push({
        name: 'publisher-list',
      });
      return true;
    },
    handleTableSelected(selectedJobsWithTable) {
      this.selectedJobsWithTable = selectedJobsWithTable;
      if (selectedJobsWithTable.length > 0) {
        this.tableSelected = true;
      } else {
        this.tableSelected = false;
        this.jobHasJustTables = false;
      }
    },

    handleInfoPageForm(data) {
      this.activeStep = 1;
      if (this.stepsAllowed === 0) {
        this.stepsAllowed += 1;
      }
      this.orderCreationFields = data;
    },
    async handleSelectedProducts(selectedProducts) {
      let totalTasks = 0;
      let jobHasDifferentTasks = false;

      selectedProducts.some((item) => {
        totalTasks += item.count;
        const taskTypes = item.tasks.some(
          (task) =>
            task.taskType === 'diagram' || task.taskType === 'instruction'
        );
        if (taskTypes) {
          jobHasDifferentTasks = true;
        } else {
          this.jobHasJustTables = true;
        }
      });

      if (jobHasDifferentTasks) {
        this.jobHasJustTables = false;
      }

      if (selectedProducts.length >= 1 && totalTasks > 0) {
        this.allowNextSelectTasks = true;
        if (this.stepsAllowed === 1) {
          this.stepsAllowed += 1;
        }
        this.selectedJobs = selectedProducts;

        await this.formatJobs();
      } else {
        if (this.stepsAllowed > 1) {
          this.stepsAllowed = 1;
        }
        this.allowNextSelectTasks = false;
      }
      if (this.$refs.selectTasks) this.$refs.selectTasks.selectedTasks = [];
      this.arrayOfObjectsSelected = [];
      this.insertedTasksInString = [];
    },

    handleSelectedConfiguration(data) {
      this.tablesConfiguration = [];
      this.activeStep += 1;
      this.allowNextSelectSites = true;
      // eslint-disable-next-line max-len
      for (let i = 0; i < data.length; i++) {
        const configurationObj = {
          partLevel: data[i].partLevel,
          _id: data[i].job._id,
          stages: data[i].stages,
          measurePointsValues: data[i].measurementPoints,
          partLevelFields: data[i].partLevelFields,
        };
        this.tablesConfiguration.push(configurationObj);
      }

      for (let index = 0; index < data.length; index++) {
        const configuration = data[index];

        for (let j = 0; j < configuration.job.tasks.length; j++) {
          const task = configuration.job.tasks[j];
          if (task.taskType === 'table') {
            this.selectedTableTasks.push(task);
          }
        }
      }
    },

    handleSelectedTasks(selectedTasks) {
      if (selectedTasks.length >= 1) {
        this.allowNextSelectSites = true;
        if (this.stepsAllowed === 2) {
          this.stepsAllowed += 1;
        }

        if (this.selectedJobsWithTable.length > 0) {
          this.stepsAllowed += 1;
        }
      }
      if (this.selectedTableTasks.length > 0) {
        for (let index = 0; index < this.selectedTableTasks.length; index++) {
          const table = this.selectedTableTasks[index];

          selectedTasks.push(table.taskNumber);
        }
      }

      selectedTasks.forEach((task) => {
        const dataToReviewPage = {
          // Job title
          title: '',
          tasks: [],
          products: '',
        };

        // Get task data (the task inside selectedTasks only
        // contains the taskNumber, which means the id)

        for (let i = 0; i < this.formattedJobs.length; i++) {
          if (this.formattedJobs[i].tasks) {
            const job = this.formattedJobs[i];

            // Finding the task inside job
            for (let j = 0; j < job.tasks.length; j++) {
              const taskInsideJob = job.tasks[j];

              if (
                taskInsideJob.taskNumber === task ||
                taskInsideJob.taskType === 'table'
              ) {
                // Searching if the job has already been created to be sent
                const index = this.arrayOfObjectsSelected.findIndex(
                  (object) => object.title === job.title
                );

                // Job has already been created in the selected data
                if (index !== -1) {
                  // Format string to add the related task to the 'tasks' string field

                  // Check if the string is already formatted or insert it
                  // Just insert if the string inside the object doesn't contain the task
                  if (
                    !this.insertedTasksInString.find(
                      (inserted) => inserted === taskInsideJob.taskNumber
                    )
                  ) {
                    this.arrayOfObjectsSelected[index].tasks.push(
                      taskInsideJob.taskName
                    );
                    this.insertedTasksInString.push(taskInsideJob.taskNumber);
                  }
                } else {
                  // Just add the data
                  dataToReviewPage.title = job.title;
                  dataToReviewPage.products = this.formatProductLine(job);
                  dataToReviewPage.tasks.push(taskInsideJob.taskName);
                  this.arrayOfObjectsSelected.push(dataToReviewPage);
                  this.insertedTasksInString.push(taskInsideJob.taskNumber);
                }
              }
            }
          }
        }
      });
    },
    handleTaskToRemove(taskObj) {
      // get index of job selected
      const index = this.arrayOfObjectsSelected.findIndex(
        (item) => item.title === taskObj.job.title
      );
      if (index !== -1) {
        if (taskObj.task) {
          const taskIndex = this.arrayOfObjectsSelected[index].tasks.indexOf(
            taskObj.task.taskName
          );
          if (taskIndex > -1) {
            this.arrayOfObjectsSelected[index].tasks.splice(taskIndex, 1);
          }

          // remove job when all tasks are removed
          if (this.arrayOfObjectsSelected[index].tasks.length === 0) {
            this.arrayOfObjectsSelected.splice(index, 1);
          }

          const removeStringIndex = this.insertedTasksInString.findIndex(
            (item) => item === taskObj.task.taskNumber
          );
          this.insertedTasksInString.splice(removeStringIndex, 1);
        } else {
          this.arrayOfObjectsSelected.splice(index, 1);
          taskObj.job.tasks.forEach((task) => {
            const removeStringIndex = this.insertedTasksInString.findIndex(
              (item) => item === task.taskNumber
            );
            this.insertedTasksInString.splice(removeStringIndex, 1);
          });
        }
      }

      // If no objects were selected then block next step
      if (this.arrayOfObjectsSelected.length < 1) {
        this.allowNextSelectSites = false;
        this.stepsAllowed -= 1;
      }
    },
    handleSelectedSites(selectedSites) {
      if (selectedSites[0]) {
        this.allowNextReview = true;
        if (this.stepsAllowed === 3) {
          this.stepsAllowed += 1;
        }

        this.selectedSitesIds = [];
        this.siteNames = [];
        selectedSites.forEach((site) => {
          this.selectedSitesIds.push(site);
          const foundSite = this.sites.find((item) => item._id === site);
          this.siteNames.push(foundSite.siteName);
        });
      } else {
        if (this.stepsAllowed > 3) {
          this.stepsAllowed = 3;
        }
        this.allowNextReview = false;
      }
    },
    allJobsChanged(jobs) {
      this.allJobs = jobs;
    },
    async formatJobs() {
      const newFormattedJobs = [];
      this.isLoading = true;

      const arrayOfPromises = [];
      this.selectedJobs.forEach((job) => {
        newFormattedJobs.push(job);
        // eslint-disable-next-line no-await-in-loop
        arrayOfPromises.push(
          this.$store.dispatch('getJobTasks', {
            jobId: job.id,
            allTasks: true,
            return: true,
          })
        );
      });

      Promise.all(arrayOfPromises).then((arrayOfTasks) => {
        arrayOfTasks.forEach((tasks, index) => {
          if (tasks.length >= 1) {
            newFormattedJobs[index].tasks = tasks;
          }
        });
        this.isLoading = false;
        this.formattedJobs = newFormattedJobs;
      });
    },
    formatProductLine(data) {
      let formattedProductLine;

      if (Array.isArray(data.workOrderName)) {
        for (let i = 0; i < data.workOrderName.length; i++) {
          if (i === 0 && data.workOrderName[i].text) {
            formattedProductLine = data.workOrderName[i].text;
          } else if (i === 0 && data.workOrderName[i]) {
            formattedProductLine = data.workOrderName[i];
          } else if (data.workOrderName[i].text) {
            formattedProductLine = formattedProductLine.concat(
              ', ',
              data.workOrderName[i].text
            );
          } else if (!data.workOrderName[i].text) {
            formattedProductLine = formattedProductLine.concat(
              ', ',
              data.workOrderName[i]
            );
          }
        }
      } else {
        formattedProductLine = data.workOrderName;
      }

      return formattedProductLine;
    },
    async nextClick(next) {
      // save the job
      if (
        (this.selectedJobsWithTable.length > 0 && this.activeStep === 5) ||
        (this.selectedJobsWithTable.length < 1 && this.activeStep === 4) ||
        (this.selectedJobsWithTable.length > 0 &&
          this.jobHasJustTables &&
          this.activeStep === 4)
      ) {
        this.isLoading = true;
        const job = {
          isEditable: false,
          type: 'workViewerLibrary',
          companyInfoText: 'Siemens Energy',
          isProduct: false,
          workStatusCode: 0,
          tagName: 'EMF-PRODUCTLIST-ITEM',
          prodCreatedByUser: this.user,
          modifier: this.user,
          ...this.orderCreationFields,
        };

        // The primary site is always the first site
        job.executingSite = this.selectedSitesIds.shift();
        job.sites = [...this.selectedSitesIds];

        let data = {};
        let tasks = [];
        let thumbnail = '';

        // eslint-disable-next-line no-shadow
        this.formattedJobs.forEach((job) => {
          if (!job.tasks || job.tasks.length === 0) {
            return;
          }
          // TODO Why not use actions.js -> clearTasksInputValues?
          job.tasks.forEach((task) => {
            if (this.insertedTasksInString.includes(task.taskNumber)) {
              if (
                task.childConfig &&
                task.childConfig[0] &&
                task.childConfig[0].lastModifiedBy
              ) {
                delete task.childConfig[0].lastModifiedBy;
              }

              delete task.modifier;
              delete task.modifyTimeout;

              //     task.baseTaskId = task._id;

              if (
                task.childConfig &&
                task.childConfig[0] &&
                task.childConfig[0].childConfig &&
                task.childConfig[0].childConfig.length > 0
              ) {
                task.childConfig[0].childConfig.map((bubble) => {
                  if (bubble.lastModifiedBy) {
                    delete bubble.lastModifiedBy;
                  }
                });
                if (
                  task.childConfig[0].childConfig[0].childConfig &&
                  task.childConfig[0].childConfig[0].childConfig.length > 0
                ) {
                  task.childConfig[0].childConfig[0].childConfig.map(
                    (bubble) => {
                      if (bubble.lastModifiedBy) {
                        delete bubble.lastModifiedBy;
                      }
                    }
                  );
                }
              }

              // set diagram thumbnail (1st diagram task)
              if (
                !thumbnail &&
                task.taskType !== 'table' &&
                task.taskType !== 'folder'
              ) {
                if (task?.childConfig[0]?.childConfig?.length > 0) {
                  if (
                    task?.childConfig[0]?.childConfig[1]?.tagName ===
                    'INPUT-DIAGRAM-ITEM-IMAGE'
                  ) {
                    thumbnail = task.childConfig[0].childConfig[1].src;
                  }
                }
              }
              tasks.push(task);
            }
          });
        });

        if (thumbnail) job.thumbnailUrl = thumbnail;
        tasks = await this.$store.dispatch('clearTasksInputValues', tasks);

        if (this.tablesConfiguration) {
          this.tablesConfiguration.forEach((config) => {
            const stages = [];
            for (let i = config.stages[0]; i <= config.stages[1]; i++) {
              stages.push(i);
            }
            config.stages = stages;
          });
          data = {
            job,
            tablesConfigurations: this.tablesConfiguration,
            tasks,
          };
        } else {
          data = {
            job,
            tasks,
          };
        }
        const jobId = await this.$store.dispatch('createNewJob', data);

        if (this.jobErrors && this.jobErrors.pop().status === 'creation') {
          console.error(this.newJobError);
          this.showFail = true;
        } else {
          this.isLoading = false;

          this.showSuccess = true;
          setTimeout(
            () =>
              this.$router.push({
                name: 'publisher-tasks',
                params: { id: jobId },
              }),
            1000
          );
        }

        return true;
      }
      return next.action();
    },
  },
};
</script>

<style lang="scss">
.label {
  color: $standard-text-color-publisher;
  font-size: 24px;
}
.pagination-link.is-current {
  background-color: $icon-active !important;
  border-color: $icon-active !important;
  color: white;
}

.md-theme-default a:not(.md-button) {
  color: black !important;
}

.md-theme-default a:not(.md-button):hover {
  text-decoration: none;
}

.container {
  margin-top: 2% !important;
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex !important;
  justify-content: space-between !important;
}

.step-item {
  margin: 0 5px 0 5px !important;
}

.step-items {
  margin-top: 20px;
}

.b-steps {
  width: 350px;
  text-align: center;
  margin: auto;
}

.b-steps .steps .step-items {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.b-steps .steps .step-items .step-item.is-active .step-marker {
  background-color: white !important;
  border-color: $siemens-logo-color !important;
  color: $siemens-logo-color !important;
}

.b-steps .steps .step-items .step-item.is-previous .step-marker {
  color: white;
  background-color: $siemens-logo-color !important;
}

.b-steps .steps + .step-content {
  padding: 0 !important;
}

.review-btn {
  color: $siemens-logo-color !important;
  &:hover {
    border: 1px solid $siemens-logo-color !important;
  }
}
</style>

<style lang="scss" scoped>
[hidden] {
  display: none !important;
}

.closeButton {
  width: 50px;
  top: 50px;
  button {
    font-size: 20px;
  }
}
</style>
