<template>
  <div>
    <div class="search-bar">
      <div style="display: flex; flex-direction: column">
        <h1 class="md-title">{{ $t('select_products') }}</h1>
        <p>{{ $t('selection_from_list') }}</p>
      </div>
      <div class="search">
        <form v-on:submit.prevent="filterJobsWithSearch">
          <b-field>
            <b-input
              :placeholder="$t('search')"
              type="search"
              icon="magnify"
              icon-clickable
              @icon-click="filterJobsWithSearch"
              class="search-input"
              v-model="search"
            >
            </b-input>
          </b-field>
        </form>
      </div>

      <b-tooltip
        v-if="activeSearch"
        label="The select all button will add 15 templates (limit)"
        position="is-bottom"
      >
        <div v-if="activeSearch" @click="handleSelectAll" class="select-all">
          <p>Select all</p>
        </div>
      </b-tooltip>

      <div
        v-if="activeSearch && this.selectedTitle.length > 0"
        @click="handleDeselectAll"
        class="select-all"
      >
        <p>Deselect all</p>
      </div>
    </div>

    <div class="select-products-container">
      <div class="table-outer" ref="div-table">
        <b-message
          :active.sync="showLimitMessage"
          title="Warning"
          type="is-warning"
          aria-close-label="Close message"
        >
          Only 15 templates were imported, the limit was reached.
        </b-message>

        <b-table
          :data="updatedTableJobs"
          :hoverable="true"
          :loading="isLoading"
          @click="handleSelectedTitle"
          :row-class="isSelected"
          :key="activeSearch"
          @sort="handleSort"
        >
          <template slot-scope="props">
            <b-table-column
              :searchable="activeSearch"
              :label="$t('product_line')"
            >
              <template
                v-if="activeSearch"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="activeSearch">
                  <b-input
                    v-if="activeSearch"
                    v-model="filterObject['workOrderName']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row.workOrderName && props.row.workOrderName.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.workOrderName }}
              </p></b-table-column
            >

            <b-table-column :searchable="activeSearch" :label="$t('title')">
              <template
                v-if="activeSearch"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="activeSearch">
                  <b-input
                    v-if="activeSearch"
                    v-model="filterObject['title']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row?.title && props.row?.title.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row?.title }}
              </p></b-table-column
            >

            <b-table-column
              :searchable="activeSearch"
              :label="$t('original_OEM')"
            >
              <template
                v-if="activeSearch"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="activeSearch">
                  <b-input
                    v-if="activeSearch"
                    v-model="filterObject['brand']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row.brand && props.row.brand.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.brand }}
              </p></b-table-column
            >

            <b-table-column :searchable="activeSearch" :label="$t('model')">
              <template
                v-if="activeSearch"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="activeSearch">
                  <b-input
                    v-if="activeSearch"
                    v-model="filterObject['model']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row.model && props.row.model.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.model }}
              </p></b-table-column
            >

            <b-table-column
              :label="$t('document_number')"
              :sortable="activeSearch"
              :custom-sort="customSortDocNumber"
              :searchable="activeSearch"
            >
              <template v-if="activeSearch" v-slot:header="{ column }">
                <b-tooltip label="Sorting according to GMTL" append-to-body>
                  {{ column.label }}
                </b-tooltip>
              </template>
              <template
                v-if="activeSearch"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="activeSearch">
                  <b-input
                    v-if="activeSearch"
                    v-model="filterObject['documentNumber']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row.documentNumber &&
                  props.row.documentNumber.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.documentNumber }}
              </p></b-table-column
            >

            <b-table-column :searchable="activeSearch" :label="$t('revision')">
              <template
                v-if="activeSearch"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="activeSearch">
                  <b-input
                    v-if="activeSearch"
                    v-model="filterObject['revision']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row.revision && props.row.revision.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.revision }}
              </p></b-table-column
            >

            <b-table-column :label="$t('task_count')">
              {{ props.row.taskCount }}
            </b-table-column>
          </template>
        </b-table>
      </div>
      <b-loading
        :is-full-page="false"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <div class="selected">
        <h5>{{ $t('selected_products') }}</h5>
        <div :key="job.id" v-for="job in selectedTitle">
          {{ job.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'publisher-select-products',
  data() {
    return {
      jobsListToTable: {},
      selected: [],
      selectedTitle: [],
      // tableAlreadySelected: false,
      showMessageTableSelected: false,
      filteredJobsWithSearch: [],
      search: '',
      skip: 0,
      limit: 15,
      tableJobs: [],
      isLoading: false,
      activeSearch: false,
      isAsc: null,
      isSortDocNumberActive: false,
      showLimitMessage: false,
      filterObject: {},
      updatedTableJobs: [],
    };
  },
  async mounted() {
    const divTable = this.$refs['div-table'];
    divTable.addEventListener('scroll', this.onScroll);

    this.$store.commit('SET_JOBS_LASTKEY', null);
    await this.getJobs();
    this.formatProductLine();
    this.formatOEM();
    this.formatModel();
  },
  computed: {
    jobs() {
      return this.$store.state.jobs;
    },
    tasks() {
      return this.$store.state.tasks;
    },
    lazyDone() {
      return this.$store.state.lazyDone;
    },
    jobsLastKey() {
      return this.$store.state.jobsLastKey;
    },
    // jobsWithTables() {
    //   const jobsFiltered = [];
    //   this.selectedTitle.forEach(selectedJob => {
    //     this.jobs.forEach(job => {
    //       const jobHastable = this.tasks.some(
    //         task => task.taskType === 'table'
    //       );
    //       // eslint-disable-next-line eqeqeq
    //       if (jobHastable && selectedJob.id == job._id) {
    //         jobsFiltered.push(job);
    //       }
    //       return false;
    //     });
    //   });
    //   return jobsFiltered;
    // },
  },

  watch: {
    search: {
      async handler(newValue) {
        if (newValue === '') {
          this.skip = 0;
          this.activeSearch = false;
          this.filterObject = {};

          this.tableJobs = [];
          this.$store.commit('SET_JOBS_LASTKEY', null);

          const divTable = this.$refs['div-table'];
          divTable.addEventListener('scroll', this.onScroll);

          await this.getJobs();
        }
      },
    },
    // Commented for now
    jobsWithTables(newValues, oldValues) {
      if (newValues.length > 0 || oldValues.length > 0) {
        this.$emit('tableHasBeenSelected', newValues);
      }
    },
    async selectedTitle(newValues) {
      if (newValues) {
        this.$emit('selectedProducts', this.selectedTitle);
      }
    },
    jobs() {
      this.formatProductLine();
      this.formatOEM();
      this.formatModel();
    },
    tableJobs: {
      deep: true,

      async handler(newValue) {
        if (Object.keys(this.filterObject).length > 0) {
          this.updatedTableJobs = newValue.filter((row) =>
            this.filterFunc(row)
          );
        } else {
          this.updatedTableJobs = newValue;
        }

        this.$emit('allJobsChanged', this.updatedTableJobs);
      },
    },

    filterObject: {
      handler(newVal) {
        const keys = Object.keys(newVal);
        if (keys.length > 0) {
          const objectEntries = Object.entries(newVal);
          if (!objectEntries.some((key_value) => key_value[1] !== '')) {
            this.filterObject = {};
            this.$cookies.remove('templates_filter');
          } else {
            this.updatedTableJobs = this.tableJobs.filter((row) =>
              this.filterFunc(row)
            );

            this.$cookies.set('templates_filter', newVal);
          }
        } else {
          this.$cookies.remove('templates_filter');
          this.updatedTableJobs = this.tableJobs;
        }
      },
      deep: true,
    },
  },

  methods: {
    customSortDocNumber(a, b, isAsc) {
      this.isSortDocNumberActive = true;

      if (this.isAsc !== null) isAsc = this.isAsc;

      const aDocumentNumber = a.documentNumber;
      const bDocumentNumber = b.documentNumber;

      const regexSearch1 = /^[a-zA-Z]{2}[0-9]{4}[a-zA-Z]{1}$/gm;
      const regexSearch2 = /^[a-zA-Z]{2}[0-9]{4}[a-zA-Z]{1}$/gm;

      if (isAsc) {
        // If one of the values has the format XX####X and the other not,
        // it will have the preference when sorting
        if (
          regexSearch1.test(aDocumentNumber) &&
          !regexSearch2.test(bDocumentNumber)
        ) {
          return -1;
        }

        regexSearch1.lastIndex = 0;
        regexSearch2.lastIndex = 0;
        if (
          !regexSearch1.test(aDocumentNumber) &&
          regexSearch2.test(bDocumentNumber)
        ) {
          return 1;
        }

        regexSearch1.lastIndex = 0;
        regexSearch2.lastIndex = 0;
        // If both of them have the format,
        // then check which one should show up first
        if (
          regexSearch1.test(aDocumentNumber) &&
          regexSearch2.test(bDocumentNumber)
        ) {
          const numbersFirst = parseInt(aDocumentNumber.split(/\D/)[2], 10);
          const numbersSecond = parseInt(bDocumentNumber.split(/\D/)[2], 10);

          if (numbersFirst > numbersSecond) return 1;
          if (numbersFirst < numbersSecond) return -1;
          return 0;
        }

        // If none of the values is on the format above, sort by alphanumeric
        return aDocumentNumber.localeCompare(bDocumentNumber, 'en', {
          numeric: true,
        });
      }

      // If one of the values has the format XX####X and the other not,
      // it will have the preference when sorting
      if (
        regexSearch1.test(aDocumentNumber) &&
        !regexSearch2.test(bDocumentNumber)
      ) {
        return -1;
      }

      regexSearch1.lastIndex = 0;
      regexSearch2.lastIndex = 0;
      if (
        !regexSearch1.test(aDocumentNumber) &&
        regexSearch2.test(bDocumentNumber)
      ) {
        return 1;
      }

      regexSearch1.lastIndex = 0;
      regexSearch2.lastIndex = 0;
      // If both of them have the format,
      // then check which one should show up first
      if (
        regexSearch1.test(aDocumentNumber) &&
        regexSearch2.test(bDocumentNumber)
      ) {
        const numbersFirst = parseInt(aDocumentNumber.split(/\D/)[2], 10);
        const numbersSecond = parseInt(bDocumentNumber.split(/\D/)[2], 10);

        if (numbersFirst > numbersSecond) return -1;
        if (numbersFirst < numbersSecond) return 1;

        return 0;
      }

      // If none of the values is on the format above, sort by alphanumeric
      const result = aDocumentNumber.localeCompare(bDocumentNumber, 'en', {
        numeric: true,
      });

      if (result === 1) return -1;
      if (result === -1) return 1;
      return result;
    },

    handleSort(field, order) {
      this.limit = 100;
      this.isAsc = order === 'asc';

      this.tableJobs = this.jobs;

      this.limit = 15;
    },

    onScroll(event) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight - 1
      ) {
        this.getJobs();
      }
    },

    isSelected(row) {
      return this.selectedTitle.length > 0 &&
        this.selectedTitle.some((job) => job.id === row._id)
        ? 'selected-row'
        : 'table-list-row';
    },

    async getJobs() {
      this.isLoading = true;

      const search = {
        type: 'editor',
        search: this.search.split(','),
      };

      const result = await this.$store.dispatch('getJobs', search);

      let startIndex;
      if (result.isNewQuery) {
        // Recreate the full table
        this.tableJobs = [];
        startIndex = 0;
      } else {
        // Just create the new list items
        startIndex = this.tableJobs.length;
      }

      for (let i = startIndex; i < this.jobs.length; i++) {
        const job = this.jobs[i];
        this.tableJobs.push(job);
      }

      this.isLoading = false;
    },

    filterJobsWithSearch() {
      this.skip = 0;
      if (!this.search) {
        this.activeSearch = false;
        this.tableJobs = [];
      } else {
        this.activeSearch = true;
      }
      return this.getJobs();
    },

    // REUSE Formats
    formatProductLine() {
      this.jobs.forEach((job) => {
        if (Array.isArray(job.workOrderName)) {
          let formattedProductLine = '';
          for (let i = 0; i < job.workOrderName.length; i++) {
            if (i === 0 && job.workOrderName[i].text) {
              formattedProductLine = job.workOrderName[i].text;
            } else if (i === 0 && job.workOrderName[i]) {
              formattedProductLine = job.workOrderName[i];
            } else if (job.workOrderName[i].text) {
              formattedProductLine = formattedProductLine.concat(
                ', ',
                job.workOrderName[i].text
              );
            } else if (!job.workOrderName[i].text) {
              formattedProductLine = formattedProductLine.concat(
                ', ',
                job.workOrderName[i]
              );
            }
          }
          job.workOrderName = formattedProductLine;
        }
      });
    },
    formatOEM() {
      this.jobs.forEach((job) => {
        if (Array.isArray(job.brand)) {
          let formattedOEM = '';
          for (let i = 0; i < job.brand.length; i++) {
            if (i === 0 && job.brand[i].text) {
              formattedOEM = job.brand[i].text;
            } else if (i === 0 && job.brand[i]) {
              formattedOEM = job.brand[i];
            } else if (job.brand[i].text) {
              formattedOEM = formattedOEM.concat(', ', job.brand[i].text);
            } else if (!job.brand[i].text) {
              formattedOEM = formattedOEM.concat(', ', job.brand[i]);
            }
          }
          job.brand = formattedOEM;
        }
      });
    },
    formatModel() {
      this.jobs.forEach((job) => {
        if (Array.isArray(job.model)) {
          let formattedModel = '';
          for (let i = 0; i < job.model.length; i++) {
            if (i === 0 && job.model[i].text) {
              formattedModel = job.model[i].text;
            } else if (i === 0 && job.model[i]) {
              formattedModel = job.model[i];
            } else if (job.model[i].text) {
              formattedModel = String(formattedModel).concat(
                ', ',
                job.model[i].text
              );
            } else if (!job.model[i].text) {
              formattedModel = String(formattedModel).concat(
                ', ',
                job.model[i]
              );
            }
          }
          job.model = formattedModel;
        }
      });
    },
    async handleSelectedTitle(job, isSelectAllActive = false) {
      const index = this.selectedTitle.findIndex((item) => item.id === job._id);

      const tasksCount = job.taskCount ?? 0;
      if (index === -1) {
        const tasks = await this.$store.dispatch('getJobTasks', {
          jobId: job._id,
          allTasks: true,
          return: true,
        });
        this.selectedTitle.push({
          title: job.title,
          id: job._id,
          count: tasksCount,
          tasks,
        });
      } else if (!isSelectAllActive) this.selectedTitle.splice(index, 1);
    },

    async handleSelectAll() {
      this.$emit('selectingProducts', true);
      this.limit = 100;
      this.getMoreJobs();
      let sortedJobs = [...this.updatedTableJobs];

      if (this.isSortDocNumberActive) {
        sortedJobs = await sortedJobs.sort(this.customSortDocNumber);
      }
      this.isLoading = true;

      for (let index = 0; index <= 14 && index < sortedJobs.length; index++) {
        const job = sortedJobs[index];
        // eslint-disable-next-line no-await-in-loop
        await this.handleSelectedTitle(job, true);
        if (index === 14) {
          this.showLimitMessage = true;
        }
      }

      this.limit = 15;
      this.isLoading = false;
    },

    handleDeselectAll() {
      this.selectedTitle = [];
    },

    filterFunc(row) {
      // eslint-disable-next-line no-restricted-syntax
      const keys = Object.keys(this.filterObject);
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          let value = row[key];

          if (value == null) return false;
          if (Number.isInteger(value)) {
            if (value !== Number(this.filterObject[key])) return false;
          } else {
            const re = new RegExp(this.filterObject[key], 'i');
            if (typeof value === 'boolean') value = `${value}`;
            if (!value.match(re)) return false;
          }
        }
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-all {
  text-align: center;
  border: 1px solid $icon-active-purple;
  width: fit-content;
  margin-left: 10px;
  border-radius: 4px;
  padding: 2px;

  p {
    margin: 4px;
    font-size: 14px;
    color: $icon-active-purple !important;
  }
}

.select-all:hover {
  cursor: pointer;
  background-color: $icon-active-purple;
  border: 1px solid $icon-active-purple;

  p {
    color: white !important;
  }
}
.container {
  margin: 0 !important;
}

.md-table + .md-table {
  margin-top: 16px;
}

.md-card.md-theme-default {
  height: 500px !important;
}
.select-products-container {
  display: flex;
  flex-direction: row;
}

.selected {
  margin-left: 5%;
  color: #777;
  p {
    margin-top: 5px;
    font-size: 12px;
    color: #cccccc;
  }
}
.md-table-head.md-table-cell-selection {
  visibility: hidden;
}

.selected-row {
  color: $icon-active;
  background-color: #cccccc;
}

.table-outer {
  max-height: 530px;
  overflow: auto;
  padding-top: 30px;
}

.search-bar {
  display: flex;
  margin-bottom: 10px;
  h1 {
    margin-right: 10px;
  }
}

.text-column-limit {
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 5;
  -moz-line-clamp: 5;
  -ms-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
  transition: 0s overflow;
  transition: 0s display;

  &:hover {
    width: 300px;
    overflow: visible;
    display: table;
    transition-delay: 1s;
  }
}
</style>
