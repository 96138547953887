<template>
  <b-dropdown
    :close-on-click="false"
    ref="fileUploadDropdown"
    :mobile-modal="false"
  >
    <template #trigger>
      <b-button icon-left="text-box-search-outline">OCR</b-button>
    </template>

    <b-dropdown-item class="custom-dropdown-item">
      <label class="file-upload">
        <input
          class="file-input"
          ref="imageUpload"
          type="file"
          multiple
          accept="image/*;capture=camera"
          @change="handleFileChooserChange"
        />
        <span class="mdi mdi-folder mdi-48px"> Choose files</span>
      </label>
    </b-dropdown-item>
    <b-dropdown-item
      class="custom-dropdown-item"
      v-if="!this.$route.name.includes('editor')"
      @click.native="onDocumentsModal"
    >
      <div class="dij-diagrambuilder-file-chooser camera">
        <b-icon icon="file-document-arrow-right" size="is-large" />
        Documents
      </div>
    </b-dropdown-item>
    <b-dropdown-item class="custom-dropdown-item" @click.native="showCamera()">
      <div class="dij-diagrambuilder-file-chooser camera">
        <b-icon icon="camera" size="is-large" />
        Take photo
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ocr-file-select',
  data() {
    return {};
  },
  methods: {
    async handleFileChooserChange(event) {
      this.$emit('onFileChange', event.srcElement.files[0]);
      this.$refs.fileUploadDropdown.toggle();
      this.$refs.imageUpload.value = null;
    },
    onDocumentsModal() {
      this.$emit('onDocumentsModal');
    },
    showCamera() {
      this.$emit('showCamera');
    },
    onInputClick() {
      this.$refs.imageUpload.disabled = false;
      this.$refs.imageUpload.click();
      this.$refs.imageUpload.disabled = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-diagrambuilder-file-chooser {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dij-diagrambuilder-file-chooser {
  border: none !important;
  align-items: center;
}
.dij-diagrambuilder-file-chooser input[type='file'] {
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.file-upload {
  cursor: pointer;
}

.camera {
  font-family: 'Siemens Sans';
  color: black;
}

.file-input {
  cursor: pointer;
}
</style>
