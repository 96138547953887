<template>
  <div v-if="invalidToken">
    <forbidden-page></forbidden-page>
  </div>
  <div v-else>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="task-container">
      <Header ref="header" :tool="tool" />
      <div class="task-items-outer">
        <div class="hint-locked">
          <span>
            <b-icon icon="lock-outline" size="is-small"></b-icon>
            {{
              tool === 'editor'
                ? $t('task_page.back_to_product_list')
                : $t('task_page.back_to_work_order_list')
            }}.
          </span>
        </div>
        <div
          :class="'task-list' + (job.jobCompleted ? ' completed-distance' : '')"
        >
          <div
            class="addTask"
            v-if="
              !isOrderTasksActive &&
              !isMultiMoveActive &&
              tool === 'editor' &&
              !readOnly &&
              (verifyAccess(job) || getJobId(job) === user._id)
            "
            @click="addTask"
          >
            <div class="addTaskIcon"></div>
            <span>{{ $t('task_page.create_new_task') }}</span>
          </div>
          <draggable
            v-model="tasks"
            :disabled="!isOrderTasksActive"
            :class="!isOrderTasksActive ? 'list-group' : 'drag-list-group'"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false"
          >
            <div
              v-for="task in getTasks"
              :class="computeTaskItemClass(task, isOrderTasksActive)"
              v-bind:key="task._id"
            >
              <div
                class="selector"
                v-if="isMultiMoveActive && isTaskAvailable(task)"
              >
                <input
                  :class="'selector-input selector-for-' + task._id"
                  type="checkbox"
                  @click="toggleTaskSelection(task)"
                />
              </div>
              <div
                class="thumbnail"
                @click="toggleTaskSelection(task)"
                v-if="isMultiMoveActive"
              >
                <img class="task-img" :src="getTaskThumbnail(task)" />
              </div>
              <div
                class="thumbnail"
                @click="goToTask(task)"
                v-if="!isMultiMoveActive"
              >
                <div class="task-img-icon">
                  <img class="img-icon" :src="getTaskIcon(task)" />
                </div>
                <img class="task-img" :src="getTaskThumbnail(task)" />
              </div>
              <div :class="completenessClass(task)">
                <div class="note-inner" style="display: inline-grid">
                  <b-tooltip
                    type="is-light"
                    :label="task.taskName"
                    position="is-bottom"
                  >
                    <div class="note-inner-task-name">{{ task.taskName }}</div>

                    <div
                      class="note-options"
                      v-if="
                        !readOnly &&
                        !tool.includes('inspector') &&
                        (verifyAccess(job) || getJobId(job) === user._id)
                      "
                    >
                      <b-dropdown
                        aria-role="list"
                        position="is-top-right"
                        @active-change="
                          (isActive) => onDropDownClick(isActive, task._id)
                        "
                        v-if="
                          !isMultiMoveActive &&
                          !isOrderTasksActive &&
                          isTaskAvailable(task)
                        "
                      >
                        <b-icon
                          class="options-icon"
                          slot="trigger"
                          role="button"
                          :icon="'dots-horizontal'"
                        ></b-icon>

                        <b-dropdown-item
                          aria-role="listitem"
                          @click="editFolder(task)"
                          v-if="task.taskType === 'folder'"
                          ><b-icon icon="pencil"></b-icon
                          >{{ $t('edit') }}</b-dropdown-item
                        >

                        <b-dropdown-item
                          aria-role="listitem"
                          @click="duplicateFolder(task)"
                          v-if="task.taskType === 'folder'"
                          ><b-icon icon="content-duplicate"></b-icon>
                          {{ $t('duplicate') }}</b-dropdown-item
                        >

                        <b-dropdown-item
                          aria-role="listitem"
                          @click="moveToFolder(task)"
                          v-if="
                            task.taskType === 'folder' && !task.hasSubfolder
                          "
                          ><b-icon icon="file-move"></b-icon>
                          {{ $t('move_folder') }}</b-dropdown-item
                        >

                        <b-dropdown-item
                          aria-role="listitem"
                          @click="editTaskName(task)"
                          v-if="task.taskType !== 'folder'"
                          ><b-icon icon="pencil"></b-icon
                          >{{ $t('edit_name') }}</b-dropdown-item
                        >
                        <b-dropdown-item
                          aria-role="listitem"
                          @click="deleteTask(task)"
                          ><b-icon icon="delete"></b-icon
                          >{{ $t('delete') }}</b-dropdown-item
                        >
                        <b-dropdown-item
                          aria-role="listitem"
                          @click="duplicate(task)"
                          v-if="task.taskType !== 'folder'"
                          ><b-icon icon="content-duplicate"></b-icon>
                          {{ $t('duplicate') }}</b-dropdown-item
                        >
                        <b-dropdown-item
                          aria-role="listitem"
                          @click="moveToFolder(task)"
                          v-if="task.taskType !== 'folder'"
                          ><b-icon icon="file-move"></b-icon>
                          {{ $t('move_folder') }}</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            isTaskCompleted(task) && task.taskType !== 'folder'
                          "
                          aria-role="listitem"
                          @click="reopen(task)"
                          ><b-icon icon="eye"></b-icon>
                          {{ $t('reopen') }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </b-tooltip>

                  <div class="diagram-icons" v-if="task.taskType === 'diagram'">
                    <div
                      v-for="icon in task.measurementsTypes || []"
                      v-bind:key="icon"
                      :class="'icon-img icon-' + icon"
                      :title="icon.charAt(0).toUpperCase() + icon.slice(1)"
                    ></div>
                    <b-tag
                      v-if="tool === 'publisher' || tool === 'inspector'"
                      rounded
                      style="margin-left: auto"
                      ><b-icon
                        icon="check"
                        type="is-success"
                        title="Signed Off"
                        v-if="isTaskCompleted(task)"
                      ></b-icon
                      ><span class="icon-completeness-ipad" v-else
                        >{{ task.completenessPercentage }} %</span
                      >
                    </b-tag>
                  </div>
                  <b-tag
                    v-if="
                      task.taskType !== 'diagram' &&
                      (tool === 'publisher' || tool === 'inspector')
                    "
                    style="margin-left: auto"
                    rounded
                    ><b-icon
                      icon="check"
                      type="is-success"
                      title="Signed Off"
                      v-if="isTaskCompleted(task)"
                    ></b-icon
                    ><span v-else
                      >{{ task.completenessPercentage }} %</span
                    ></b-tag
                  >
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <b-modal
        :active.sync="isEditNameActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        class="edit-name-modal"
      >
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Edit {{ editTask ? editTask.taskName : '' }}
            </p>
          </header>
          <section class="modal-card-body">
            <md-field>
              <label>Task Name</label>
              <md-input required v-model="editTask.taskName"></md-input>
            </md-field>
          </section>
          <footer class="modal-card-foot">
            <button class="" type="button" @click="isEditNameActive = false">
              Close
            </button>
            <button class="" @click="saveTaskName()">Save</button>
          </footer>
        </div>
      </b-modal>
      <b-modal
        :active.sync="isMoveTaskActive"
        :width="640"
        scroll="keep"
        :can-cancel="['escape', 'outside']"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Select folder to move.</p>
        </header>
        <section class="modal-card-body">
          <b-button
            v-for="folder in foldersToMove"
            :icon-left="folder._id === '' ? 'folder-remove' : 'folder'"
            :key="folder._id"
            size="is-large"
            class="folder-button"
            @click="moveTask(folder)"
          >
            {{ folder.taskName }}
          </b-button>
        </section>
        <footer class="modal-card-foot">
          <button style="width: 100%" type="button" @click="cancelMoveTask()">
            Close
          </button>
        </footer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import jwt_decode from 'jwt-decode';
import Header from '../layout/TasksHeader';
import LockHelper from '../../helpers/LockHelper';
import KeyHandlerMixin from '../../mixins/KeyHandlerMixin';

export default {
  name: 'task-page',
  components: { Header, draggable },
  mixins: [KeyHandlerMixin],
  data() {
    return {
      jobId: null,
      foldersToMove: [],
      isMoveTaskActive: false,
      invalidToken: false,
      tokenData: null,
      isOrderTasksActive: false,
      isMultiMoveActive: false,
      isEditNameActive: false,
      editTask: { taskName: '' },
      showCompletenessTooltip: true,
      readOnly: false,
      isLoading: false,
      tasks: [],
      selectedTasks: [],
      unwatchJob: null,
      unwatchTasks: null,
      prevFolder: null,
      hasSubfolderID: null,
      dropdownOpenedForTask: '',
    };
  },

  props: {
    tool: String,
  },
  watch: {
    tasks(newValue) {
      this.$store.dispatch('setUpdatedTasks', newValue);
    },
  },
  computed: {
    getTasks() {
      return this.tasks;
    },
    job() {
      return this.$store.state.currentJob;
    },
    user() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    this.unwatchJob = this.$store.watch(
      () => {
        return this.$store.state.currentJob;
      },
      (updatedJob) => {
        if (updatedJob.approvalStatus === 'requested') {
          this.readOnly = true;
        }
        if (updatedJob) {
          this.$forceUpdate();
        }
      },
      {
        deep: true,
      }
    );
  },

  async mounted() {
    this.isLoading = true;
    // fetch the job by its id on the route
    const { id, folderId, subfolderId } = this.$route.params;
    this.jobId = id;

    // await this.$store.dispatch('getJob', id);

    try {
      const options = {
        jobId: id,
      };
      if (folderId) options.folderId = folderId;
      if (subfolderId) options.folderId = subfolderId;
      this.prevFolder = folderId;
      this.hasSubfolderID = subfolderId;
      await this.$store.dispatch('getJobTasks', options);
      this.tasks = this.$store.state.tasks.sort((a, b) =>
        a.taskOrder > b.taskOrder ? 1 : -1
      );

      this.unwatchTasks = this.$store.watch(
        () => {
          return this.$store.state.tasks;
        },
        (newTasks) => {
          this.tasks = newTasks;
          this.$forceUpdate();
        }
      );
    } catch (error) {
      this.$router.push({
        name: '404',
      });
      return false;
    }

    if (this.job && this.job.childConfig && this.job.childConfig.length === 0) {
      const newChild = [];
      this.job.childConfig.push(newChild);
    }

    if (this.tool === 'publisher' || this.tool === 'inspector') {
      this.formatCompletenessPercentage();
    }

    const { token } = this.$route.query;
    if (token) {
      this.tokenData = await jwt_decode(token, process.env.JWT_SECRET);
      this.readOnly = this.tokenData.action === 'view';
      await this.checkToken(token);
      await this.hideIfToken();

      if (this.tool === 'editor') {
        this.checkApprovalStatus();
      }

      if (this.tool === 'editor' && this.job.type !== 'productLibrary') {
        this.$router.push({
          name: '404',
        });
      }

      if (
        (this.tool === 'publisher' || this.tool === 'inspector') &&
        this.job.type !== 'workViewerLibrary' &&
        this.job.type !== 'workOrderLibrary'
      ) {
        this.$router.push({
          name: '404',
        });
      }
    }

    this.$store.commit('SET_TASK_OBJECT', {});
    this.isLoading = false;

    this.$nextTick(() => {
      this.$watch('$refs.header.isOrderTasksActive', async (newValue) => {
        this.isOrderTasksActive = newValue;
      });

      this.$watch('$refs.header.isMultiMoveActive', async (newValue) => {
        // If the mode is disabled enable moveTask
        if (newValue) {
          this.isMultiMoveActive = newValue;
        } else {
          this.moveToFolder();
        }
      });

      this.$watch('$refs.header.isMultiSelect', async (newValue) => {
        const tasksToSelect = this.tasks.filter(
          (item) => item.taskType !== 'folder'
        );
        if (newValue) {
          tasksToSelect.forEach((task) => {
            document.querySelector(`.selector-for-${task._id}`).checked = true;
            this.selectedTasks.push(task);
          });
        } else {
          tasksToSelect.forEach((task) => {
            document.querySelector(`.selector-for-${task._id}`).checked = false;
            this.selectedTasks = [];
          });
        }
      });

      this.$watch('$refs.header.updateJob', async (newValue) => {
        this.isLoading = true;
        if (newValue) {
          await this.$store.dispatch('updateJobSimple', this.job);
          await this.updateThumbnail();
        }
        this.isLoading = false;
      });
    });

    if (this.tool === 'editor') {
      if (
        this.user.role !== 'admin' &&
        this.user._id !== this.job.prodCreatedByUser._id
      ) {
        this.readOnly = true;
      }

      return false;
    }

    if (!folderId) {
      this.updateJobCompleteness();
      const folders = this.tasks.filter((item) => item.taskType === 'folder');
      folders.forEach((folder) => {
        this.updateFolderCompleteness(folder);
      });
    }
    this.onUpdateCallback = {
      callback: this.saveTaskName,
      isUpdate: true,
    };

    // Check if theres any document in pouchDB that this to be uploaded
    await this.$store.dispatch('syncImagesOnIndexedDB');
  },
  beforeDestroy() {
    this.unwatchJob();
    this.unwatchTasks();
  },
  methods: {
    computeTaskItemClass(task, isOrderTasksActive) {
      let classes = 'task-item';

      if (isOrderTasksActive) {
        classes += ' task-order';
      }

      if (task.taskType === 'folder') {
        if (task._id === this.dropdownOpenedForTask) {
          classes += ' folder';
        } else {
          classes += ' folder folder-clip-path';
        }
      }

      return classes;
    },
    onDropDownClick(isActive, taskId) {
      this.dropdownOpenedForTask = isActive ? taskId : '';
    },
    isTaskCompleted(task) {
      return task.workStatusCode
        ? task.workStatusCode.toString() === '2'
        : false;
    },
    completenessClass(task) {
      if (
        this.isTaskCompleted(task) &&
        (this.tool === 'publisher' || this.tool === 'inspector')
      ) {
        return 'note-complete';
      }
      if (
        task.completenessPercentage !== 0 &&
        (this.tool === 'publisher' || this.tool === 'inspector')
      ) {
        return 'note-medium';
      }

      return 'note-begin';
    },

    async hideIfToken() {
      const arrowBackButton = document.getElementsByClassName('back-button');
      const menuNavbar = document.getElementsByClassName('navbar-item');
      const headerLogo = document.getElementsByClassName('header-logo');

      const gotoProductListButton = document.getElementById('gotoProductList');
      const elementsToHide = document.getElementsByClassName(
        'header-inner headerTool'
      );
      const breadcrumb = document.getElementsByClassName('breadcrumb');
      if (breadcrumb && breadcrumb.length > 0) {
        const arrowPath = breadcrumb[0].getElementsByTagName('pre');
        breadcrumb[0].style = 'margin-left: 10% !important';
        arrowPath[0].style.display = 'none';
      }

      if (gotoProductListButton) gotoProductListButton.style.display = 'none';

      if (arrowBackButton && arrowBackButton.length > 0)
        arrowBackButton[0].style.display = 'none';

      Array.from(elementsToHide).forEach((element) => {
        element.style.display = 'none';
      });

      if (menuNavbar && menuNavbar.length > 0)
        menuNavbar[0].style.display = 'none';

      if (headerLogo && headerLogo.length > 0) headerLogo[0].href = '';
    },

    async checkToken(token) {
      try {
        this.tokenData = await jwt_decode(token, process.env.JWT_SECRET);
        this.readOnly = this.tokenData.action === 'view';

        const currentTime = Date.now() / 1000;
        if (currentTime > this.tokenData.exp) {
          this.invalidToken = true;
          this.$router.push('/logout');
        }
        if (this.tokenData.action === 'view') {
          this.readOnly = true;
        }
      } catch (err) {
        this.invalidToken = true;
        this.$router.push('/logout');
      }
    },
    async getJobFolder(jobId, folderId, taskFolderId) {
      const options = {
        jobId,
        folderId,
        return: true,
      };
      const folders = await this.$store.dispatch('getJobTasks', options);
      folders.forEach((folder) => {
        if (folder.taskType === 'folder') {
          if (folder._id !== taskFolderId) this.foldersToMove.push(folder);
        }
      });
    },
    getTaskIcon(task) {
      if (task.taskType === 'diagram') {
        return '/img/diagram_icon.png';
      }
      if (task.taskType === 'instruction') {
        return '/img/Instruction_icon.png';
      }
    },
    getTaskThumbnail(task) {
      if (task.taskType === 'diagram') {
        if (task.diagram && task.diagram.src) {
          const lastPart = task.diagram.src.split('/').pop();
          const allButLast = task.diagram.src.split('/').slice(0, -1).join('/');

          const imageSource = `${allButLast}/${encodeURIComponent(lastPart)}`;
          // For local env we need to point to the local s3
          if (window.location.host.includes('localhost')) {
            return `http://localhost:4566/images${imageSource}`;
          }
          return imageSource;
        }
        return '/img/image-empty.png';
      }
      if (task.taskType === 'table') {
        return '/img/excel-sheet-icon.jpg';
      }
      // eslint-disable-next-line no-nested-ternary
      if (task.taskType === 'instruction') {
        if (task.thumbnailUrl) {
          // For local env we need to point to the local s3
          if (window.location.host.includes('localhost')) {
            return `http://localhost:4566/images${task.thumbnailUrl}`;
          }

          return task.thumbnailUrl;
        }
        return '/img/ic-assignment-black-24-px.png';
      }
    },

    goToTask(task) {
      const taskId = task._id;
      this.isLoading = true;
      if (this.$route.query.token) {
        const { token } = this.$route.query;

        if (task.taskType === 'folder' && task.folderId) {
          return this.$router.push({
            path: `/view/${this.tool}/${this.job._id}/folder/${task.folderId}/${task.taskNumber}`,
            query: { token },
          });
        }

        if (task.taskType === 'folder') {
          return this.$router.push({
            path: `/view/${this.tool}/${this.job._id}/folder/${taskId}`,
            query: { token },
          });
        }

        this.$router.push({
          name: `${this.tool}-template`, // editor-template || publisher || inspector
          params: { id: this.job._id, taskNumber: taskId },
          query: { token },
        });
      } else {
        if (task.taskType === 'folder' && task.folderId) {
          return this.$router.push({
            path: `/view/${this.tool}/${this.job._id}/folder/${task.folderId}/${task.taskNumber}`,
          });
        }

        if (task.taskType === 'folder') {
          return this.$router.push({
            path: `/view/${this.tool}/${this.job._id}/folder/${taskId}`,
          });
        }
        // Removed becouse now we should let user to see their work but not to change it
        // if (this.job.jobCompleted && this.tool === 'inspector') {
        //   return;
        // }

        this.$router.push({
          name: `${this.tool}-template`,
          params: { id: this.job._id, taskNumber: taskId },
        });
      }
    },

    editTaskName(task) {
      this.isEditNameActive = true;
      this.editTask = { ...task };
    },

    editFolder(folderTask) {
      // eslint-disable-next-line no-nested-ternary
      const routeTool = this.tool.includes('editor')
        ? 'editor'
        : this.tool.includes('publisher')
        ? 'publisher'
        : '';

      const url = {
        name: 'create-folder',
        params: { id: this.job._id, module: routeTool },
        query: { edit: true },
      };

      if (this.$route.query.token) {
        url.query.token = this.$route.query.token;
      }

      if (folderTask.folderId) {
        url.query.subfolderId = folderTask._id;
      } else {
        url.query.folderId = folderTask._id;
      }

      this.$router.push(url);
    },
    async saveTaskName() {
      const updatedTask = this.editTask;
      if (
        updatedTask.childConfig &&
        updatedTask.childConfig[0] &&
        updatedTask.childConfig[0].taskName
      ) {
        updatedTask.childConfig[0].taskName = updatedTask.taskName;
      }

      const taskIndex = this.tasks.findIndex(
        (task) => task._id === updatedTask._id
      );
      this.tasks[taskIndex] = updatedTask;
      try {
        await this.$store.dispatch('updateTask', updatedTask);
        this.isEditNameActive = false;
      } catch (e) {
        this.$store.dispatch('setErrorMessage', e.message);
      }
    },

    async reopen(task) {
      try {
        this.editTask = { ...task };
        this.editTask.workStatusCode = 1;
        const updatedTask = this.editTask;
        const taskIndex = this.tasks.findIndex(
          (taskFind) => taskFind._id === updatedTask._id
        );
        this.tasks[taskIndex] = updatedTask;
        await this.$store.dispatch('updateTask', updatedTask);
        const newJob = { ...this.job };
        newJob.jobCompleted = false;
        newJob.jobCompletedByUser = null;
        newJob.notifiedExternal = false;
        await this.$store.dispatch('updateJobSimple', newJob);
      } catch (e) {
        this.$store.dispatch('setErrorMessage', e.message);
      }
    },

    async duplicateFolder(folder) {
      await this.$store.dispatch('duplicateFolder', folder);
    },

    async duplicate(task) {
      const clearedTask = { ...task };
      clearedTask.baseTaskId = task._id;
      await this.$store.dispatch('duplicateTask', clearedTask);
    },

    cancelMoveTask() {
      this.isMoveTaskActive = false;
      this.isMultiMoveActive = false;
      this.selectedTasks = [];
    },

    async moveTask(folder) {
      try {
        await this.$store.dispatch('moveTasks', {
          tasks: this.selectedTasks,
          folder,
        });
        this.selectedTasks.forEach((selectedTask) => {
          selectedTask.folderId = folder._id;
          this.tasks.splice(
            this.tasks.findIndex((task) => task._id === selectedTask._id),
            1
          );
        });
      } catch (e) {
        this.$store.dispatch('setErrorMessage', e.message);
      } finally {
        this.isMoveTaskActive = false;
        this.isMultiMoveActive = false;
        this.selectedTasks = [];
      }
    },

    async moveToFolder(task) {
      if (task) {
        // Support the previous file copy method
        this.toggleTaskSelection(task);
      } else if (this.selectedTasks.length !== 0) {
        [task] = this.selectedTasks;
      } else {
        // There's no file selected
        this.cancelMoveTask();
        return;
      }
      if (this.foldersToMove.length === 0) {
        // folders and tasks list
        if (task.folderId) {
          this.tasks.forEach((folder) => {
            if (folder.taskType === 'folder' && folder._id !== task._id) {
              this.foldersToMove.push(folder);
            }
          });
          this.getJobFolder(task.jobId, null, task.folderId);
          if (this.hasSubfolderID) {
            this.foldersToMove.push({
              _id: this.prevFolder,
              taskName: 'Root',
            });
            this.getJobFolder(task.jobId, this.prevFolder, task.folderId);
          } else {
            this.foldersToMove.push({ _id: '', taskName: 'Root' });
          }
        } else {
          // inside a folder, only tasks list
          // TODO get folders from job other than what we are in
          this.getJobFolder(task.jobId, null, null);
        }
      }
      this.isMoveTaskActive = true;
    },

    async deleteTask(task) {
      const number = task._id;
      let message = 'Are you sure you want to delete?';
      if (task.taskType === 'folder') {
        message = 'Deleting a folder will delete all tasks inside it.';
      }

      this.$buefy.dialog.confirm({
        message,
        type: 'is-dark',
        hasIcon: true,
        icon: 'delete',
        confirmText: 'Delete',
        onConfirm: async () => {
          try {
            // get the index of the task to be removed
            const taskIndex = this.tasks.findIndex(
              (item) => item._id === number
            );
            // delete the task
            this.isLoading = true;
            await this.$store.dispatch('deleteTask', this.tasks[taskIndex]._id);
            this.$store.dispatch('getJobDeletedTasks', { jobId: this.jobId });
            // Update taskOrder of the remaining tasks
            await this.$store.dispatch('updateTasksOrder', {
              jobId: this.job._id,
            });
            this.isLoading = false;

            // remove the task from jobArray
            // this.tasks.splice(taskIndex, 1);
          } catch (e) {
            this.$store.dispatch('setErrorMessage', e.message);
          }
        },
      });
    },

    addTask() {
      if (this.$route.query.token) {
        const { token } = this.$route.query;

        this.$router.push({
          name: `${this.tool}-create-task`,
          params: { id: this.job._id },
          query: { token },
        });
      } else {
        this.$router.push({
          name: `${this.tool}-create-task`,
          params: { id: this.job._id },
        });
      }
    },

    formatCompletenessPercentage() {
      this.tasks.forEach((task) => {
        let numberPercentage;
        if (!task.completenessPercentage) {
          numberPercentage = 0;
        } else if (typeof task.completenessPercentage === 'number') {
          numberPercentage = task.completenessPercentage;
        } else if (task.completenessPercentage.includes('%')) {
          numberPercentage = task.completenessPercentage.split('%');
          numberPercentage = parseInt(numberPercentage[0], 10);
        } else {
          // Expect a string with a number only
          numberPercentage = parseInt(task.completenessPercentage, 10);
        }
        task.completenessPercentage = parseInt(numberPercentage, 10);
      });
    },
    verifyAccess(job) {
      if (
        job.prodCreatedByUser &&
        (this.user.privil === 1 ||
          this.user.location === job.prodCreatedByUser.location ||
          this.user.location === job.executingSite ||
          job.sites.some((site) => site === this.user.location))
      ) {
        return true;
      }
      return false;
    },
    getJobId(job) {
      if (job.prodCreatedByUser && job.prodCreatedByUser._id) {
        return job.prodCreatedByUser._id;
      }
      return '';
    },
    checkApprovalStatus() {
      if (
        this.job?.approvalStatus === 'approved' ||
        this.job?.approvalStatus === 'requested'
      ) {
        this.readOnly = true;
      }
    },
    toggleTaskSelection(task) {
      const foundTaskIndex = this.selectedTasks.findIndex(
        (selectedTask) => selectedTask._id === task._id
      );

      if (foundTaskIndex === -1) {
        this.selectedTasks.push(task);
        if (this.isMultiMoveActive) {
          document.querySelector(`.selector-for-${task._id}`).checked = true;
        }
      } else {
        this.selectedTasks.splice(foundTaskIndex, 1);
        if (this.isMultiMoveActive) {
          document.querySelector(`.selector-for-${task._id}`).checked = false;
        }
      }
    },
    async updateFolderCompleteness(folder) {
      const folderId = folder._id;
      let count = 0;
      let tasksLength = 0;
      let workStatusCode = 2;
      const tasks = await this.$store.dispatch('getJobTasks', {
        jobId: this.job._id,
        folderId,
        return: true,
      });
      tasks.forEach((task) => {
        let value;
        tasksLength++;
        if (typeof task.completenessPercentage === 'string') {
          if (task.completenessPercentage.includes('%')) {
            value = parseInt(task.completenessPercentage.split('%')[0], 10);
          } else {
            value = parseInt(task.completenessPercentage, 10);
          }
        } else {
          value = task.completenessPercentage;
        }
        if (task.workStatusCode !== 2) {
          workStatusCode = 1;
        }
        count += value;
      });
      if (tasksLength === 0) return false;
      const percentageCompleteness = (count / tasksLength).toFixed(0);
      folder.completenessPercentage = `${percentageCompleteness}`;
      folder.workStatusCode = workStatusCode;
      await this.$store.dispatch('updateTask', folder);
    },
    async updateJobCompleteness() {
      let count = 0;
      let tasksLength = 0;
      const tasks = await this.$store.dispatch('getJobTasks', {
        jobId: this.job._id,
        allTasks: true,
        return: true,
      });
      tasks.forEach((task) => {
        let value;
        if (task.taskType === 'folder') {
          return;
        }
        tasksLength++;
        if (typeof task.completenessPercentage === 'string') {
          if (task.completenessPercentage.includes('%')) {
            value = parseInt(task.completenessPercentage.split('%')[0], 10);
          } else {
            value = parseInt(task.completenessPercentage, 10);
          }
        } else {
          value = task.completenessPercentage;
        }
        count += value;
      });
      const percentageCompleteness = (count / tasksLength).toFixed(2);
      const newJob = { ...this.job };
      newJob.completenessPercentage = `${percentageCompleteness} %`;
      await this.$store.dispatch('updateJobSimple', newJob);
    },

    async updateThumbnail() {
      // First task inside the job
      let tasks;
      try {
        tasks = await this.$store.dispatch('getJobTasks', {
          jobId: this.jobId,
          allTasks: true,
          return: true,
        });
      } catch (error) {
        console.log('failed to get tasks');
        return false;
      }

      if (!tasks || tasks.length === 0) return false;
      const folders = tasks
        .filter((item) => item.taskType === 'folder')
        .sort((a, b) => (a.taskOrder > b.taskOrder ? 1 : -1));
      const tasksWithoutFolders = tasks
        .filter((item) => !item.folderId && item.taskType !== 'folder')
        .sort((a, b) => (a.taskOrder > b.taskOrder ? 1 : -1));

      if (tasksWithoutFolders.length > 0) {
        return this.updateThumbnailAux(tasksWithoutFolders);
      }
      const tasksInsideFolder = tasks.filter(
        (item) => item.folderId === folders[0]._id
      );
      return this.updateThumbnailAux(tasksInsideFolder);
    },
    async updateThumbnailAux(tasks) {
      let task;

      // Get the first diagram or table
      // If there are none, select the first task
      for (let i = 0; i < tasks.length; i++) {
        const item = tasks[i];
        if (item.taskType === 'diagram' || item.taskType === 'table') {
          task = item;
          break;
        }
      }
      if (!task) {
        [task] = tasks;
      }

      // Ignore if there are no tasks or if the only task is a folder
      if (!task) return false;
      if (task?.taskType === 'folder') return false;

      const newThumbnail = this.getTaskThumbnail(task);

      // Only save if there are changes
      if (newThumbnail !== this.job.thumbnailUrl) {
        const newJob = { ...this.job };

        newJob.thumbnailUrl = newThumbnail;

        await this.$store.dispatch('updateJobSimple', newJob);
      }
    },
    isTaskAvailable(task) {
      return LockHelper.isTaskAvailable(task, this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
.task-container {
  font-family: 'Siemens Sans';
  max-width: 100%;
  margin: 0;
}

.task-img {
  width: auto;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.task-img-icon {
  position: absolute;
  top: 5%;
  left: 80%;
}

.img-icon {
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.thumbnail {
  position: relative;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb !important;
}

.list-group {
  display: contents;
}

.drag-list-group {
  display: flex;
  flex-wrap: wrap;
}

.modal-card-body {
  padding: 5px 20px;
  overflow: hidden;
}
.modal-card-head,
.modal-card-foot {
  border-radius: 0px;
}

.modal-card-foot {
  padding: 0;
  button {
    margin: 0;
    height: 50px;
    border: 1px solid #ccc;
    border-left: none;
    width: 50%;
    background-color: #f6f6f6;
    color: #888888;
    font-size: 16px;
    font-family: 'Siemens Roman';
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}
.edit-name-modal {
  .modal-card {
    width: 450px !important;
  }
}

.task-order {
  cursor: grab;
  .thumbnail {
    pointer-events: none !important;
  }
}
.breadcrumb {
  display: inherit;
}

.folder {
  background-color: #afafaf;
  color: #222;
}

.folder-clip-path {
  clip-path: polygon(0 0, 30% 0, 34% 10%, 100% 10%, 200% 110%, -100% 110%);
}

.folder-button {
  margin-right: 15px;
  padding: 40px;
  margin-top: 15px;
}

.selector {
  position: absolute;
  &-input {
    width: 30px;
    height: 30px;
  }
}
/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  /* For portrait layouts only */
  .task-items-outer .task-list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 30px;
    height: calc(100% - 100px);
  }

  .task-items-outer .task-item {
    display: block;
    width: 200px;
    height: 210px;
    margin: 0px 8px 30px 4px;
    color: #333;
    font-family: 'Siemens Sans';

    .thumbnail {
      width: 100%;
      height: 110px;
    }
  }

  .note-begin,
  .note-medium,
  .note-complete {
    height: 100px;

    .note-inner {
      .note-inner-task-name {
        max-width: 100%;
        max-height: none;
        white-space: normal;
      }
    }
  }

  .task-items-outer .task-item {
    height: 210px;
  }

  .note-inner-task-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.completed-distance {
  padding-top: 41px !important;
}

/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  /* For landscape layouts only */
  .task-item {
    width: 200px;

    .thumbnail {
      height: 110px;
    }
    .diagram-icons {
      margin-top: 15px;
    }
  }

  .task-items-outer .note-begin,
  .task-items-outer .note-medium,
  .task-items-outer .note-complete {
    height: 110px;
  }

  .task-items-outer .task-item {
    height: 210px;
    margin: 0px 25px 30px 4px;
  }

  .note-inner-task-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .task-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 46px 45px 40px;
    height: calc(100% - 100px);
    overflow-y: auto;
  }

  .note-begin,
  .note-medium,
  .note-complete {
    .note-inner {
      .note-inner-task-name {
        max-width: 100%;
        max-height: none;
        white-space: normal;
      }
    }
  }
}

@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .task-items-outer .task-list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 30px;
    height: calc(100% - 100px);
  }

  .task-items-outer .task-item {
    display: block;
    width: 200px;
    height: 210px;
    margin: 0px 8px 30px 4px;
    color: #333;
    font-family: 'Siemens Sans';

    .thumbnail {
      width: 100%;
      height: 110px;
    }
  }

  .note-begin,
  .note-medium,
  .note-complete {
    height: 100px;

    .note-inner {
      .note-inner-task-name {
        max-width: 100%;
        max-height: none;
        white-space: normal;
      }
    }
  }

  .task-items-outer .task-item {
    height: 210px;
  }

  .note-inner-task-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.note-options {
  margin-left: auto;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  .task-item {
    width: 200px;

    .thumbnail {
      height: 110px;
    }
    .diagram-icons {
      margin-top: 15px;
    }
  }

  .task-items-outer .note-begin,
  .task-items-outer .note-medium,
  .task-items-outer .note-complete {
    height: 110px;
  }

  .task-items-outer .task-item {
    height: 210px;
    margin: 0px 25px 30px 4px;
  }

  .note-inner-task-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .task-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 46px 45px 40px;
    height: calc(100% - 100px);
    overflow-y: auto;
  }

  .note-begin,
  .note-medium,
  .note-complete {
    .note-inner {
      .note-inner-task-name {
        max-width: 100%;
        max-height: none;
        white-space: normal;
      }
    }
  }
}
</style>
