<template>
  <div class="task-container" :id="task._id">
    <TaskName :taskName="task.taskName" :id="task._id" />
    <div>
      <div id="table-title">
        <span>INSPECTION DIAGRAM</span>
        <span>
          <span v-if="task.documentNumber" class="document-info"
            >Document Number: <span>{{ task.documentNumber }}</span></span
          >
          <span v-if="task.revision" class="document-info"
            >Revision: <span>{{ task.revision }}</span></span
          >
        </span>
      </div>
      <diagram-builder
        class="dij-diagram-fullscreen"
        :taskProp="task"
        :show-toolbar="false"
        :show-list="false"
        :report-mode="true"
        @zoomChange="onZoomChange"
        :tool="'inspector'"
      />
    </div>

    <div class="table-measurements" v-if="task.lengthData.length > 0">
      LENGTH MEASUREMENTS
      <b-table :data="task.lengthData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column field="label" label="Label">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column
            field="partName"
            label="Part Name"
            v-if="hasPartNameColumn(task.lengthData)"
          >
            {{ props.row.partName }}
          </b-table-column>
          <b-table-column
            field="description"
            label="Description"
            v-if="hasDescriptionColumn(task.lengthData)"
          >
            {{ props.row.description ?? '-' }}
          </b-table-column>
          <b-table-column
            v-if="props.row.values.length > 0"
            v-for="item in props.row.values"
            :field="item.name"
            :label="item.name"
            :class="'col-value' + colorValueDimLen(item.diff)"
          >
            {{ item.value }}
            <template v-if="item.diff && isInternalReport">
              <div>{{ item.diff }}</div>
            </template>
          </b-table-column>

          <b-table-column
            field="Expected value"
            label="Expected value"
            :visible="isInternalReport"
          >
            {{ props.row.expectedValue }}
          </b-table-column>

          <b-table-column
            field="tolerance1"
            label="Tolerance1"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance1 }}
          </b-table-column>

          <b-table-column
            field="tolerance2"
            label="Tolerance2"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance2 }}
          </b-table-column>

          <b-table-column field="unit" label="Unit">
            {{ props.row.unit }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="table-measurements" v-if="task.diameterData.length > 0">
      DIAMETER MEASUREMENTS
      <b-table :data="task.diameterData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column field="label" label="Label">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column
            field="partName"
            label="Part Name"
            v-if="hasPartNameColumn(task.diameterData)"
          >
            {{ props.row.partName }}
          </b-table-column>
          <b-table-column
            field="description"
            label="Description"
            v-if="hasDescriptionColumn(task.diameterData)"
          >
            {{ props.row.description ?? '-' }}
          </b-table-column>
          <b-table-column
            v-if="props.row.values.length > 0"
            v-for="item in props.row.values"
            :field="item.name"
            :label="item.name"
            :class="'col-value' + colorValueDimLen(item.diff)"
          >
            {{ item.value }}
            <template v-if="item.diff && isInternalReport">
              <div>{{ item.diff }}</div>
            </template>
          </b-table-column>

          <b-table-column
            field="expectedValue"
            label="Expected value"
            :visible="isInternalReport"
          >
            {{ props.row.expectedValue }}
          </b-table-column>

          <b-table-column
            field="tolerance1"
            label="Tolerance1"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance1 }}
          </b-table-column>

          <b-table-column
            field="tolerance2"
            label="Tolerance2"
            :visible="isInternalReport"
          >
            {{ props.row.tolerance2 }}
          </b-table-column>

          <b-table-column field="unit" label="Unit">
            {{ props.row.unit }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div
      class="table-measurements"
      v-if="runoutDataSingleMeasurment.length > 0"
    >
      <RunoutReportTable
        :runoutData="runoutDataSingleMeasurment"
        :isInternalReport="isInternalReport"
        :colorValueDimLen="colorValueDimLen"
      />
    </div>

    <div
      class="table-measurements"
      v-if="runoutDataMultipleMeasurment.length > 0"
    >
      <RunoutReportTable
        :runoutData="runoutDataMultipleMeasurment"
        :isInternalReport="isInternalReport"
        :colorValueDimLen="colorValueDimLen"
      />
    </div>

    <div class="table-measurements" v-if="task.visualData.length > 0">
      VISUAL INSPECTION
      <b-table :data="task.visualData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column
            field="label"
            label="Label"
            :class="
              props.row.condition !== 'FINE' && isInternalReport && 'red-value'
            "
          >
            {{ props.row.label }}
          </b-table-column>
          <b-table-column
            field="partName"
            label="Part Name"
            v-if="hasPartNameColumn(task.visualData)"
          >
            {{ props.row.partName }}
          </b-table-column>
          <b-table-column
            field="description"
            label="Description"
            v-if="hasDescriptionColumn(task.visualData)"
          >
            {{ props.row.description ?? '-' }}
          </b-table-column>
          <b-table-column
            field="condition"
            label="Condition"
            :class="
              props.row.condition !== 'FINE' && isInternalReport && 'red-value'
            "
          >
            {{ props.row.condition }}
          </b-table-column>

          <b-table-column
            field="value"
            label="	Severity"
            :class="
              props.row.condition !== 'FINE' && isInternalReport && 'red-value'
            "
          >
            {{ props.row.value }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="table-measurements" v-if="task.genericData.length > 0">
      GENERIC MEASUREMENTS
      <b-table :data="task.genericData" :striped="true" :narrowed="true">
        <template slot-scope="props">
          <b-table-column field="label" label="Label">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column
            field="partName"
            label="Part Name"
            v-if="hasPartNameColumn(task.genericData)"
          >
            {{ props.row.partName }}
          </b-table-column>
          <b-table-column field="measurementName" label="Measurement Name">
            {{ props.row.measurementName }}
          </b-table-column>
          <b-table-column
            field="description"
            label="Description"
            v-if="hasDescriptionColumn(task.genericData)"
          >
            {{ props.row.description ?? '-' }}
          </b-table-column>

          <b-table-column
            v-if="props.row.values.length > 0"
            v-for="item in props.row.values"
            :field="item.name"
            :label="item.name"
            :class="'col-value' + colorValueDimLen(item.diff)"
          >
            {{ item.value }}
            <template v-if="item.diff && isInternalReport">
              <div>{{ item.diff }}</div>
            </template>
          </b-table-column>

          <b-table-column
            field="expectedValue"
            label="Expected value"
            :visible="isInternalReport"
            v-if="hasGenericToleranceColumns()"
          >
            {{ props.row.expectedValue }}
          </b-table-column>

          <b-table-column
            field="tolerance1"
            label="Tolerance1"
            :visible="isInternalReport"
            v-if="hasGenericToleranceColumns()"
          >
            {{ props.row.tolerance1 }}
          </b-table-column>

          <b-table-column
            field="tolerance2"
            label="Tolerance2"
            :visible="isInternalReport"
            v-if="hasGenericToleranceColumns()"
          >
            {{ props.row.tolerance2 }}
          </b-table-column>

          <b-table-column
            field="unit"
            label="Unit"
            v-if="hasGenericToleranceColumns()"
          >
            {{ props.row.unit }}
          </b-table-column>

          <b-table-column
            field="lastModifiedBy"
            label="Inspector"
            :visible="isInternalReport"
          >
            {{ props.row.lastModifiedBy }}
          </b-table-column>

          <b-table-column field="lastModifiedAt" label="Date">
            {{ props.row.lastModifiedAt }}
          </b-table-column>
        </template>
      </b-table>
    </div>
    <Attachments :task-prop="task" :isFreeFinding="true" />
    <Findings :task-prop="task" />
    <Attachments :task-prop="task" />
    <div id="divider" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import DiagramBuilder from '../../../components/DiagramBuilder/DiagramBuilder';
import Findings from '../components/Findings';
import Attachments from '../components/Attachments';
import TaskName from '../components/TaskName';
import RunoutReportTable from '../components/RunoutReportTable';
import ExternalData from '../components/ExternalData';

export default {
  name: 'Diagram',
  props: {
    taskProp: {
      type: Object,
      required: true,
    },
  },

  components: {
    DiagramBuilder,
    Findings,
    Attachments,
    TaskName,
    RunoutReportTable,
    ExternalData,
  },
  computed: {
    task() {
      return this.taskProp;
    },

    isInternalReport() {
      return this.$store.state.ReportsModule.isInternalReport;
    },

    runoutDataSingleMeasurment() {
      return this.task.runoutData.filter(
        (inspection) => !inspection.hasMultipleMeasurements
      );
    },

    runoutTableBubbles() {
      return this.task.diagram.inspections.filter(
        (inspection) => inspection.type === 'runout' && inspection.inTable
      );
    },

    runoutDataMultipleMeasurment() {
      return this.task.runoutData.filter(
        (inspection) => inspection.hasMultipleMeasurements
      );
    },
  },
  methods: {
    colorValueDimLen(value) {
      if (!this.isInternalReport) return '';
      if (value !== undefined) return ' red-value';
      return '';
    },

    isNOTInTolarances(value, tolerance) {
      if (!this.isInternalReport) return false;
      if (isNaN(value)) return false;
      const negativeTolerance = Math.abs(tolerance) * -1;
      const positiveTolerance = Math.abs(tolerance);
      const res =
        parseFloat(value) >= negativeTolerance &&
        parseFloat(value) <= positiveTolerance;
      return !res;
    },
    onZoomChange: debounce(function (zoomLevel) {
      const updatedTask = { ...this.task };
      updatedTask.zoomLevel = zoomLevel;

      if (updatedTask.lengthData) delete updatedTask.lengthData;
      if (updatedTask.diameterData) delete updatedTask.diameterData;
      if (updatedTask.runoutData) delete updatedTask.runoutData;
      if (updatedTask.visualData) delete updatedTask.visualData;
      if (updatedTask.genericData) delete updatedTask.genericData;
      if (updatedTask.attachmentData) delete updatedTask.attachmentData;

      this.$store.dispatch('updateTask', updatedTask);
    }, 500),

    hasDescriptionColumn(measurements) {
      return measurements.some((measurement) => measurement.hasDescription);
    },

    hasPartNameColumn(measurements) {
      return measurements.some((measurement) => measurement.inTable);
    },

    hasGenericToleranceColumns() {
      return this.task.genericData.some(
        (measurement) =>
          measurement.expectedValue && !isNaN(measurement.expectedValue)
      );
    },
  },
};
</script>

<style scoped lang="scss">
#task-header {
  height: 35px;
  font-family: 'Siemens Sans';
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 35px;
}

#table-title {
  font-family: 'Siemens Sans';
  font-size: 18px;
  background-color: white;
  padding: 10px;
}

#divider {
  height: 10px;
  background-color: #ccc;
  width: 100%;
  margin-top: 20px;
}

#table-inspection {
  margin-top: 20px;
  background-color: #fff;
  padding: 10px;
  font-family: 'Siemens Sans';
  font-size: 18px;
}

.table-measurements {
  background-color: #fff;
  padding: 10px;
  font-family: 'Siemens Sans';
  font-size: 18px;
  margin-top: 20px;
}

.dij-diagram-fullscreen {
  height: calc(100vh - 50px - 50px);
}

.red-value {
  color: #e87061;
}

#table-title {
  font-family: 'Siemens Sans';
  font-size: 18px;
  background-color: white;
  padding: 10px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
  display: flex;
  justify-content: space-between;
}

.document-info {
  border-left: solid;
  border-width: 1px;
  padding: 5px;
  margin-left: 10px;
  border-color: #ccc;
}

.document-info span {
  color: #aaa;
}

.col-value {
  white-space: pre-line;
}
</style>
