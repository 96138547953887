<template>
  <div class="select-tasks-container">
    <h1 class="select-tasks-title">{{ $t('select_tasks') }}</h1>
    <div class="everything-selector-container">
      <div @click="handleSelectEverything()" class="select-everything">
        <p>Select Everything</p>
      </div>
      <div
        v-if="selectedTasks.length > 0"
        @click="handleDeselectEverything()"
        class="deselect-everything"
      >
        <p>Deselect Everything</p>
      </div>
    </div>

    <div class="jobs" v-for="job in jobsToRender" :key="job.id">
      <ul>
        <p class="job-title">
          {{ job.title }}
        </p>
        <div class="selection-buttons">
          <div @click="handleSelectAll(job)" class="select-all">
            <p>{{ $t('select_all') }}</p>
          </div>
          <div
            v-if="
              selectedTasks.length > 0 && enabledJobsToDeselect.includes(job.id)
            "
            @click="handleDeselectAll(job)"
            class="deselect-all"
          >
            <p>{{ $t('deselect_all') }}</p>
          </div>
        </div>
        <div class="tasks-list">
          <li v-for="task in tasksToRender(job)" :key="task.taskNumber">
            <div class="cards-list">
              <div
                :title="task.folderTitle"
                class="folder-title"
                v-if="task.folderId !== ''"
              >
                {{ task.folderTitle }}
              </div>
              <div
                :class="
                  selectedTasks.includes(task.taskNumber)
                    ? 'task-card selected-task'
                    : 'task-card'
                "
                @click="handleTaskClick(task, job)"
              >
                <b-icon
                  v-if="selectedTasks.includes(task.taskNumber)"
                  class="check-icon"
                  icon="check"
                ></b-icon>

                <p
                  :class="
                    selectedTasks.includes(task.taskNumber)
                      ? 'task-title selected-task-title'
                      : 'task-title'
                  "
                >
                  {{ task.taskName }}
                </p>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
// import TableConfiguration from './TableConfiguration';

export default {
  name: 'publisher-select-tasks',
  props: {
    selectedJobs: Array,
    formattedJobs: Array,
  },
  components: {
    // 'table-configuration': TableConfiguration,
  },
  data() {
    return {
      jobHasTable: true,

      jobsListToTable: {},
      selected: [],
      selectedTitle: [],
      jobsToRender: [],
      selectedTasks: [],
      enabledJobsToDeselect: [],
      indexToRemove: [],
      filteredJobs: [],
      taskToRemove: {
        job: '',
        task: '',
      },
    };
  },
  async mounted() {},
  computed: {
    jobs() {
      return this.$store.state.jobs;
    },
  },
  watch: {
    formattedJobs: {
      handler() {
        const filtered = this.formattedJobs.filter(
          (job) =>
            job.tasks &&
            job.tasks.length > 0 &&
            job.tasks.some((task) => task.taskType !== 'table')
        );
        this.jobsToRender = filtered;
      },
      deep: true,
    },
    selectedTasks(newValue) {
      this.$emit('selectedTasks', newValue);
    },
  },

  methods: {
    tasksToRender(job) {
      const filtered = job.tasks.filter((task) => task.taskType !== 'table');
      const hierarchy = this.buildTaskHierarchy(filtered);

      const hierarchyTasks = this.flattenHierarchy(hierarchy);

      const showTasks = hierarchyTasks.filter(
        (task) => task.taskType !== 'folder'
      );
      showTasks.forEach((showTask, i) => {
        if (showTask.folderId && showTask.folderId !== '') {
          const folderTask = job.tasks.filter((taskFilter) => {
            return taskFilter._id === showTask.folderId;
          })[0];
          if (folderTask) {
            showTasks[i].folderTitle = folderTask.taskName;
          }
        }
      });
      return showTasks;
    },

    buildTaskHierarchy(tasks) {
      const tasksMap = new Map();
      const hierarchy = [];

      // Sort tasks based on taskType and folderId
      tasks.sort((a, b) => {
        if (a.folderId && !b.folderId) {
          return 1; // Folder comes after root task
        }
        if (!a.folderId && b.folderId) {
          return -1; // Root task comes before folder
        }
        return a.taskOrder - b.taskOrder; // Sort by taskOrder
      });

      tasks.forEach((task) => {
        task.children = [];
        tasksMap.set(task._id, task);
      });

      // Traverse the tasks and assign children to their parent folders
      tasks.forEach((task) => {
        if (task.folderId) {
          const parentTask = tasksMap.get(task.folderId);
          if (parentTask) {
            parentTask.children.push(task);
          }
        } else {
          hierarchy.push(task); // Task is at the root level
        }
      });
      return hierarchy;
    },
    flattenHierarchy(hierarchy, folderLevel = 0) {
      const flattened = [];

      hierarchy.forEach((task) => {
        task.folderLevel = folderLevel;
        flattened.push(task);

        if (task.children && task.children.length) {
          const children = this.flattenHierarchy(
            task.children,
            folderLevel + 1
          );
          flattened.push(...children);
        }
      });

      return flattened;
    },

    handleTaskClick(task, job) {
      // If task is already selected, remove it
      if (this.selectedTasks.includes(task.taskNumber)) {
        // If task is a table remove all table tasks
        if (task.taskType === 'table') {
          for (let index = this.selectedTasks.length - 1; index >= 0; index--) {
            const taskToRemove = this.selectedTasks[index];

            const { taskType } = job.tasks.find(
              (taskInsideJob) => taskInsideJob.taskNumber === taskToRemove
            );

            if (taskType === 'table') {
              this.taskToRemove.task = task;
              this.taskToRemove.job = job;

              this.removeTask(this.taskToRemove);

              this.selectedTasks.splice(index, 1);
            }
          }
        } else {
          const index = this.selectedTasks.indexOf(task.taskNumber);

          this.taskToRemove.task = task;
          this.taskToRemove.job = job;

          this.removeTask(this.taskToRemove);

          this.selectedTasks.splice(index, 1);
        }
      } else {
        // If task is not selected yet
        // If task is a table, all tables must be selected
        if (task.taskType === 'table') {
          job.tasks.map((findTaskTable) => {
            if (findTaskTable.taskType === 'table') {
              this.selectedTasks.push(findTaskTable.taskNumber);
            }
          });
        } else {
          this.selectedTasks.push(task.taskNumber);
        }
        /* sometimes this is not called, or the selectedTasks
        is not updated yet by the time the $emit event is called */
        setTimeout(() => {
          this.$emit('selectedTasks', this.selectedTasks);
        }, 100);
      }
      this.enableDeselect(job, task);
    },

    handleSelectAll(job) {
      job.tasks.forEach((task) => {
        if (!this.selectedTasks.includes(task.taskNumber)) {
          this.enableDeselect(job, task);
          this.selectedTasks.push(task.taskNumber);
          this.$emit('selectedTasks', this.selectedTasks);
        }
      });
    },

    handleSelectEverything() {
      this.jobsToRender.forEach((job) => {
        job.tasks.forEach((task) => {
          if (!this.selectedTasks.includes(task.taskNumber)) {
            this.enableDeselect(job, task);
            this.selectedTasks.push(task.taskNumber);
            this.$emit('selectedTasks', this.selectedTasks);
          }
        });
      });
      this.$emit('selectedTasks', this.selectedTasks);
    },

    enableDeselect(job) {
      const index = this.enabledJobsToDeselect.lastIndexOf(job.id);

      if (index !== -1) {
        const jobHasActiveTask = this.selectedTasks.some((selectedTask) => {
          for (
            let indexNumber = 0;
            indexNumber < job.tasks.length;
            indexNumber++
          ) {
            const taskInsideJob = job.tasks[indexNumber];
            if (taskInsideJob.taskNumber === selectedTask) return true;
          }
        });
        if (!jobHasActiveTask) {
          this.enabledJobsToDeselect.splice(index, 1);
        }
      } else {
        this.enabledJobsToDeselect.push(job.id);
      }
    },

    handleDeselectAll(job) {
      const selectedTasksFullLength = this.selectedTasks.length;
      // first iteration to send event to parent component to remove the task
      for (let i = 0; i < selectedTasksFullLength; i++) {
        const selectedTask = this.selectedTasks[i];
        const index = job.tasks.findIndex(
          (task) => task.taskNumber === selectedTask
        );
        if (index !== -1) {
          this.taskToRemove.task = job.tasks[i];
          this.taskToRemove.job = job;
          this.removeTask(this.taskToRemove);
        }
      }
      /* knowing that is a deselectAll,
      we can remove all tasks from selected after the first iteration */
      job.tasks.forEach((task) => {
        const index = this.selectedTasks.indexOf(task.taskNumber);
        if (index > -1) {
          this.selectedTasks.splice(index, 1);
        }
      });
      this.enableDeselect(job);
    },

    handleDeselectEverything() {
      this.selectedTasks.forEach((taskId) => {
        const job = this.jobsToRender.find((jobToFind) => {
          return jobToFind.tasks.some(
            (taskToFind) => taskToFind._id === taskId
          );
        });

        const taskToFind = job.tasks.find((item) => item._id === taskId);

        this.removeTask({ task: taskToFind, job });
      });
      this.selectedTasks = [];
      this.enabledJobsToDeselect = [];
    },

    removeTask(taskToRemove) {
      this.$emit('taskToRemove', taskToRemove);
    },
  },
};
</script>

<style lang="scss">
.select-tasks-title {
  color: #777;
  font-size: 24px;
}
.select-tasks-container {
  display: flex;
  overflow: auto;
  width: 100% !important;
  height: 70vh;
  flex-direction: column;
}
.jobs {
  display: flex;
  flex-direction: column;
  color: $standard-text-color-publisher;
  max-width: 80vw;
  margin-top: 2% !important;

  .job-title {
    font-size: 20px;
  }
}

.job-title-table {
  margin-top: 10px;
  font-size: 20px;
}

.tasks-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 14px !important;
  position: relative;

  p {
    color: $standard-text-color-publisher;
  }

  .job-without-task {
    margin-bottom: 10px;
  }
}

.task-card {
  text-align: center;
  line-height: 120px;
  margin: auto;
  width: 136px;
  height: 136px;
  overflow: auto;
  position: relative;
  margin: 0 14px 14px 0;
  border: 1px solid $standard-background-color;
  border-radius: 4px;
  color: $standard-background-color;
  cursor: pointer;

  .task-title {
    overflow: auto;
    text-align: center;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
  }
}
.selected-task {
  border: 2px solid $icon-active !important;
  display: flex;
  flex-direction: column;
}

.check-icon {
  color: $icon-active;
}

.selected-task-title {
  margin-top: 20%;
  color: $icon-active !important;
}
.select-all {
  text-align: center;
  border: 1px solid $icon-active;
  width: fit-content;

  p {
    margin: 4px;
    font-size: 14px;
    color: $icon-active !important;
  }
}

.select-all:hover {
  cursor: pointer;
  background-color: $icon-active;
  border: 1px solid $icon-active;

  p {
    color: white !important;
  }
}

.deselect-all {
  margin-left: 10px;
  text-align: center;
  border: 1px solid $icon-active;
  width: fit-content;

  p {
    margin: 4px;
    font-size: 14px;
    color: $icon-active !important;
  }
}

.deselect-all:hover {
  cursor: pointer;
  background-color: $icon-active;
  border: 1px solid $icon-active;

  p {
    color: white !important;
  }
}

.everything-selector-container {
  display: flex;
  flex-direction: row;
}

.select-everything {
  text-align: center;
  border: 1px solid $icon-active-purple;
  width: fit-content;

  p {
    margin: 4px;
    font-size: 14px;
    color: $icon-active-purple !important;
  }
}

.select-everything:hover {
  cursor: pointer;
  background-color: $icon-active-purple;
  border: 1px solid $icon-active-purple;

  p {
    color: white !important;
  }
}

.selection-buttons {
  display: inline-flex;
}

.deselect-everything {
  margin-left: 10px;
  text-align: center;
  border: 1px solid $icon-active-purple;
  width: fit-content;

  p {
    margin: 4px;
    font-size: 14px;
    color: $icon-active-purple !important;
  }
}

.deselect-everything:hover {
  cursor: pointer;
  background-color: $icon-active-purple;
  border: 1px solid $icon-active-purple;

  p {
    color: white !important;
  }
}

.table-configuration-container {
  margin-top: 20px;
}

.folder-title {
  position: absolute;
  bottom: 100%;
  max-height: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.cards-list {
  position: relative;
  margin: 14px 0px 0px 0;
}
</style>
