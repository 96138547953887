<template>
  <div>
    RUNOUT MEASUREMENTS
    <b-table :data="runoutData" :striped="true" :narrowed="true">
      <template slot-scope="props">
        <b-table-column field="label" label="Label">
          {{ props.row.label }}
        </b-table-column>
        <b-table-column
          field="partName"
          label="Part Name"
          v-if="hasPartNameColumn(runoutData)"
        >
          {{ props.row.partName }}
        </b-table-column>

        <b-table-column
          field="description"
          label="Description"
          v-if="hasDescriptionColumn(runoutData)"
        >
          {{ props.row.description ?? '-' }}
        </b-table-column>

        <b-table-column
          v-if="props.row.values.length > 0"
          v-for="item in props.row.values"
          :field="item.name"
          :label="item.name"
          :class="
            props.row.hasMultipleMeasurements
              ? undefined
              : 'col-value' + colorValueDimLen(item.diff)
          "
        >
          {{ item.value }}
          <template
            v-if="
              item.diff &&
              isInternalReport &&
              !props.row.hasMultipleMeasurements
            "
          >
            <div>{{ item.diff }}</div>
          </template>
        </b-table-column>

        <b-table-column
          v-if="props.row.hasMultipleMeasurements"
          field="tir"
          label="TIR"
          :class="'col-value' + colorValueDimLen(props.row.tirDiff)"
        >
          {{ props.row.tir }}
          <template v-if="props.row.tirDiff && isInternalReport">
            <div>{{ props.row.tirDiff }}</div>
          </template>
        </b-table-column>

        <b-table-column
          field="expectedValue"
          label="Tolerance"
          :visible="isInternalReport"
        >
          {{ props.row.expectedValue }}
        </b-table-column>

        <b-table-column field="unit" label="Unit">
          {{ props.row.unit }}
        </b-table-column>

        <b-table-column
          field="lastModifiedBy"
          label="Inspector"
          :visible="isInternalReport"
        >
          {{ props.row.lastModifiedBy }}
        </b-table-column>

        <b-table-column field="lastModifiedAt" label="Date">
          {{ props.row.lastModifiedAt }}
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: ['runoutData', 'isInternalReport', 'colorValueDimLen'],
  name: 'RunoutReportTable',
  methods: {
    hasDescriptionColumn(measurements) {
      return measurements.some((measurement) => measurement.hasDescription);
    },
    hasPartNameColumn(measurements) {
      return measurements.some((measurement) => measurement.inTable);
    },
  },
};
</script>
<style scoped>
.red-value {
  color: #e87061;
}
</style>
